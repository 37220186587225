import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../common/api';
import axios from 'axios';
import Select from 'react-select';
import { Success, Error } from '../../common/swal';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Imagevalidation, onErrorImage } from '../../common/validate';
import Spinner from 'react-bootstrap/Spinner';
import { AC_HANDLE_MAINCATEGORY_CHANGE, AC_LIST_MAINCATEGORY_DETAIL, AC_MAINCATEGORY_LIST_SPINNER, AC_RESET_MAINCATEGORY_DETAIL, AC_VIEW_MAINCATEGORY_DETAIL, AC_EMPTY_MAINCATEGORY_FEES_SPINNER } from '../../actions/maincategory';
import ExportPdfComponent from '../../utilities/ExportPDF/exportpdf';
import AgGridPiganationComponent from '../../utilities/AgGridReact/aggridpagination';

const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    image: '',
    CategorynameError: false,
    imageError: false,
    descriptionError: false,
    communicationemailValidError: false,
    columnDefs: [
        { headerName: 'Main Category', field: 'categoryname', headerClass: 'ag-center-header', width: 200, floatingFilter: true },
        {
            headerName: 'Image', field: 'image', width: 130, floatingFilter: false, headerClass: 'ag-center-header',
            cellRendererFramework: function (params) {
                if (params.data) {
                    return <div>
                        <img style={{ width: '50px', height: '50px', borderRadius: '0px' }}
                            src={ImportedURL.LIVEURL + params.data.image}
                            alt=""
                            onError={onErrorImage}
                        />
                    </div>
                }
            }
        },

        {
            headerName: 'Description', field: 'description', headerClass: 'ag-center-header', width: 130, floatingFilter: true,
        },
        {
            headerName: 'Status', width: 130, field: 'status', headerClass: 'ag-center-header', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data) {
                    if (params.data.status) {
                        return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                    } else {
                        return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                    }
                }
            }
        },
        {
            headerName: 'Actions', width: 150, field: 'actions', headerClass: 'ag-center-header', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        // "width": 200,
        // "filter": true,
        // "sortable": true,
        // "resizable": true
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    },
    perPage: 25,
}
class MainCategory extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
        this.setState({ image: "" });
        this.props.resetcategory();
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['categoryname', 'description'],
            fileName: 'maincategory.csv',
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };
    exportPDF = () => {
        const exportData = this.props.CategoryState.listCategory;
        const title = "Main Category";
        const headers = [['#', 'Categoryname', 'Description']];
        const objName = ['categoryname', 'description'];
        const pdfName = "maincategory.pdf";
        ExportPdfComponent(exportData, title, headers, objName, pdfName)
    }
    uploadhandleChange = e => {
        const imgvalidate = Imagevalidation(e.target.files[0]);
        if (imgvalidate) {
            this.setState({ imageError: false })
            this.setState({ image: e.target.files[0] });
            var reader = new FileReader();
            var url = reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = function (e) {
                this.setState({
                    imageSrc: [reader.result]
                })
            }.bind(this);
            this.props.HandleInputChange('image', e.target.files[0]);
        } else {
            this.setState({ name: '' });
            Error('Invalid file extension');

        }
    }
    handleOnchange = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.props.HandleInputChange(name, value);
        if (name === 'categoryname') {
            this.setState({ categoryname: value });
            var categoryname = value;
            if (categoryname) {
                this.setState({ CategorynameError: false })
            } else {
                this.setState({ CategorynameError: true })
            }
        }
        if (name === 'type') {
            this.setState({ type: value });
            var type = value;
            if (type) {
                this.setState({ descriptionError: false })
            } else {
                this.setState({ descriptionError: true })
            }
        }
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ descriptionError: false, CategorynameError: false, imageError: false })
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.ViewSpinner();
            this.props.ViewCategory(event.data._id);
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.deleteCategory + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'maincategories' })
                        .then((data) => {
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    submit = () => {
        const { CategoryState } = this.props;
        const data = CategoryState.Category;

        let valid = 1
        if (!data.categoryname) {
            this.setState({ CategorynameError: true });
            valid = 0
        }
        if (!data.image) {
            this.setState({ imageError: true });
            valid = 0
        }
        if (!data.description) {
            this.setState({ descriptionError: true });
            valid = 0
        }

        if (valid) {
            var formData = new FormData();
            formData.append("categoryname", data.categoryname);
            formData.append("image", data.image);
            formData.append("description", data.description);

            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addCategory, formData)
                    .then((formdata) => {
                        Success(formdata.statusText);
                        this.setState({ save: false });
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        this.props.resetcategory();
                        let btn = document.getElementById("closeModal");
                        btn.click();

                    }).catch(({ response }) => {
                        this.setState({ save: false });
                        if (response.status == 409) {
                            Error('Category Name Already Exist')
                        } else {
                            Error(response.statusText)
                        }

                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updateCategory + '/' + data._id, formData)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.resetcategory();
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        this.setState({ Redirect: true })
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });

            }
        }
    }
    // componentDidMount(){
    //     this.props.listcategory();

    // }
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {
                // Grid params for filter and sort
                const page = params.endRow / this.state.perPage;
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                this.props.ListSpinner();
                await this.props.listcategory({ ...query, perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {} });
                const rowData = this.props.CategoryState.listCategory;
                const total = this.props.CategoryState.totelCategories;

                const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
                const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
                const template =
                    // '<div><button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-file-text-o" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
                    editOption
                    // + deleteOption
                    + '</div>'
                rowData.forEach(object => {
                    object.actions = template;
                });

                params.successCallback(rowData, total);

            }
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    componentDidUpdate(prevProps, prevState) {
        // To set data once grid api is updated
        if (this.gridApi) {
            if (prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
    }
    render() {
        const { CategoryState } = this.props;
        const data = CategoryState.Category;
        const rowData = CategoryState.listCategory;
        const ViewSpinner = CategoryState.ViewSpinner;
        const ListSpinner = CategoryState.ListSpinner;
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        if (data !== undefined) {
            var imageData = data.image;
        }

        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-category"><h6 className="highlights_breadcrump">Main Category List</h6></Link></p>
                    </div>
                    <div>
                        <div className="section-body pt-3">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-line-chart"></i></div>
                                                <h3 className="card-title">Main Category List</h3>
                                                <div class="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <ul className="nav nav-tabs page-header-tab"></ul>
                                                        <div className="header-action">
                                                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                        </div>
                                                    </div>
                                                    <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                        <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                        <label className="form-label">Limit</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <AgGridPiganationComponent spinner={ListSpinner} rowData={rowData} state={this.state} onRowClicked={(e) => this.onRowClicked(e)} onGridReady={(e) => this.onGridReady(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-xl" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <div className='rounded_icon'><i className="fa fa-line-chart mr-2 "></i></div>
                                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} Category</h5>
                                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                            <img src='../../assets/images/cancel.png' />
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            <div className='col-lg-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Main Category<span className="ml-1" style={{ color: 'red' }}> *</span></label>
                                                                    <input type="text" className="form-control" placeholder="Main Category" name="categoryname" onChange={this.handleOnchange} value={data.categoryname} />
                                                                    <div className="invalid-feedback" style={{ display: this.state.CategorynameError ? "block" : 'none' }}>Main Category is required</div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Image<span className="ml-1" style={{ color: 'red' }}> *</span></label>
                                                                    <input name="image" type="file" accept="image/png,image/jpg,image/jpeg" className="form-control" onChange={this.uploadhandleChange} />
                                                                    <div className="invalid-feedback" style={{ display: this.state.imageError ? "block" : 'none' }}>Image is required</div>
                                                                </div>
                                                                <div className="form-group">
                                                                    {
                                                                        this.state.modalType == "Add"
                                                                        &&
                                                                        (this.state.image
                                                                            ?
                                                                            <div style={{ width: '200px', height: '100px' }}>
                                                                                <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : null} alt='12' onError={onErrorImage} />
                                                                            </div>
                                                                            : '')
                                                                    }
                                                                    {
                                                                        this.state.modalType == "Edit"
                                                                            ?
                                                                            <div className="form-group">
                                                                                <div style={{ width: '200px', height: '100px' }}>
                                                                                    <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : ImportedURL.LIVEURL + imageData} alt="" onError={onErrorImage} />
                                                                                </div>
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Description<span style={{ color: 'red' }}> *</span></label>
                                                                    <textarea className="form-control w-100" id="description" name='description' rows="5" placeholder='Description' onChange={this.handleOnchange} value={data.description}></textarea>
                                                                    <div className="invalid-feedback" style={{ display: this.state.descriptionError ? "block" : 'none' }}>Description is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.modalType == "Edit" ?
                                                    <div className="card-footer text-right mandatory">
                                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                        <div className="" >
                                                            {
                                                                this.state.saving ?
                                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                                    :
                                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                                            }
                                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                                        </div>
                                                    </div>
                                                    : <div className="card-footer text-right mandatory">
                                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                        <div className="" >
                                                            {
                                                                this.state.saving ?
                                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                                    :
                                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                            }
                                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id='overlay-model' style={{ display: ViewSpinner ? 'block' : 'none' }}></div>
                            {ViewSpinner ?
                                <div className='model_loader_poss'>
                                    <img className='loader_img_style_model' src='../../assets/images/recycle loader.gif' />
                                    <Spinner className='spinner_load_model' variant="info" >
                                    </Spinner>
                                </div>
                                : ''}
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    CategoryState: state.maincategory,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            HandleInputChange: AC_HANDLE_MAINCATEGORY_CHANGE,
            listcategory: AC_LIST_MAINCATEGORY_DETAIL,
            resetcategory: AC_RESET_MAINCATEGORY_DETAIL,
            ViewCategory: AC_VIEW_MAINCATEGORY_DETAIL,
            ViewSpinner: AC_EMPTY_MAINCATEGORY_FEES_SPINNER,
            ListSpinner: AC_MAINCATEGORY_LIST_SPINNER
        }, dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MainCategory);