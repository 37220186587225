const LIVE = true;
const LIVEURL = LIVE
  ? "https://kokannest.myaistar.com/"
  : "http://0.0.0.0:6000/";
const ROOTURL = LIVEURL + "api/v1/";
const API = {
  addJobTitle: ROOTURL + "jobtitle/addJobTitle",
  listJobTitle: ROOTURL + "jobtitle/listJobTitle",
  viewJobTitle: ROOTURL + "jobtitle/viewJobTitle",
  updateJobTitle: ROOTURL + "jobtitle/updateJobTitle",
  deleteJobTitle: ROOTURL + "jobtitle/deleteJobTitle",
  addHotel: ROOTURL + "hotel/addHotel",
  listHotel: ROOTURL + "hotel/listHotel",
  viewHotel: ROOTURL + "hotel/viewHotel",
  updateHotel: ROOTURL + "hotel/updateHotel",
  deleteHotel: ROOTURL + "hotel/deleteHotel",
  addJob: ROOTURL + "job/addJob",
  listJob: ROOTURL + "job/listJob",
  getJob: ROOTURL + "job/getAllJob",
  viewApplyJob: ROOTURL + "job/getJobInfo",
  viewJob: ROOTURL + "job/viewJob",
  socialShare: ROOTURL + "job/socialShare",
  getJob: ROOTURL + "job/getAllJob",
  updateJob: ROOTURL + "job/updateJob",
  deleteJob: ROOTURL + "job/deleteJob",
  shareJob: ROOTURL + "job/shareJob",
  login: ROOTURL + "account/login",
  accountDetails: ROOTURL + "account/accountDetails",
  getHistory: ROOTURL + "history/getHistory",
  previleges: ROOTURL + "account/previleges",
  getPrevileges: ROOTURL + "account/getPrevileges",
  addUser: ROOTURL + "user/addUser",
  addUserPrevilege: ROOTURL + "user/addUserPrevilege",
  listUser: ROOTURL + "user/listUser",
  viewUser: ROOTURL + "user/viewUser",
  updateUser: ROOTURL + "user/updateUser",
  deleteUser: ROOTURL + "user/deleteUser",
  applyJob: ROOTURL + "jobapplication/applyJob",
  listJobApplication: ROOTURL + "jobapplication/listJobApplication",
  getSettings: ROOTURL + "settings/getSettings",
  updateSettings: ROOTURL + "settings/updateSettings",
  sendReview: ROOTURL + "review/sendReview",
  sendBulkReview: ROOTURL + "review/sendBulkReview",
  sendFeedback: ROOTURL + "review/sendFeedback",
  getFeedback: ROOTURL + "review/getFeedback",
  getReview: ROOTURL + "review/getReview",
  getReport: ROOTURL + "review/getReport",
  getMonthlyReport: ROOTURL + "review/getMonthlyReport",
  listTicket: ROOTURL + "ticket/listTicket",
  viewTicket: ROOTURL + "ticket/viewTicket",
  addCommonIssue: ROOTURL + "ticket/addCommonIssue",
  listCommonIssues: ROOTURL + "ticket/listCommonIssues",
  viewCommonIssue: ROOTURL + "ticket/viewCommonIssue",
  updateCommonIssue: ROOTURL + "ticket/updateCommonIssue",
  deleteCommonIssue: ROOTURL + "ticket/deleteCommonIssue",
  listTicketUser: ROOTURL + "ticket/listTicketUser",
  listComments: ROOTURL + "ticket/listComments",
  addComment: ROOTURL + "ticket/addComment",
  forgotPassword: ROOTURL + "account/forgotPassword",
  changePassword: ROOTURL + "account/changePassword",
  listNotification: ROOTURL + "notification/listNotification",
  updateAdmin: ROOTURL + "account/updateAdmin",

  // status common
  statusChange: ROOTURL + "common/statusChange",

  // category
  addCategory: ROOTURL + "maincategory/addCategory",
  listCategory: ROOTURL + "maincategory/listCategory",
  viewCategory: ROOTURL + "maincategory/viewCategory",
  updateCategory: ROOTURL + "maincategory/updateCategory",
  deleteCategory: ROOTURL + "maincategory/deleteCategory",

  // subcategory
  addSubcategory: ROOTURL + "subcategory/addSubcategory",
  listSubcategory: ROOTURL + "subcategory/listSubcategory",
  viewSubcategory: ROOTURL + "subcategory/viewSubcategory",
  updateSubcategory: ROOTURL + "subcategory/updateSubcategory",
  deleteSubategory: ROOTURL + "subcategory/deleteSubategory",

  // currency
  addCurrency: ROOTURL + "currency/addCurrency",
  listCurrency: ROOTURL + "currency/listCurrency",
  viewCurrency: ROOTURL + "currency/viewCurrency",
  updateCurrency: ROOTURL + "currency/updateCurrency",
  softDeleteCurrency: ROOTURL + "currency/softDeleteCurrency",
  deleteCurrency: ROOTURL + "currency/deleteCurrency",

  // country
  addCountry: ROOTURL + "country/addCountry",
  listCountry: ROOTURL + "country/listCountry",
  listCountryCategory: ROOTURL + "country/listCountryCategory",
  viewCountry: ROOTURL + "country/viewCountry",
  updateCountry: ROOTURL + "country/updateCountry",
  deleteCountry: ROOTURL + "country/deleteCountry",

  // users
  addUser: ROOTURL + "users/addUser",
  listUser: ROOTURL + "users/listUser",
  viewUser: ROOTURL + "users/viewUser",
  updateUser: ROOTURL + "users/updateUser",
  kycverification: ROOTURL + "users/kycverification",
  softDeleteUser: ROOTURL + "users/softDeleteUser",
  updateProfile: ROOTURL + " users/updateProfile",
  deleteUser: ROOTURL + "users/deleteUser",
  documentverification: ROOTURL + "users/documentverification",

  // categories
  addcategories: ROOTURL + "categories/addcategories",
  listcategories: ROOTURL + "categories/listcategories",
  viewcategories: ROOTURL + "categories/viewcategories",
  updatecategories: ROOTURL + "categories/updatecategories",
  softDeletecategories: ROOTURL + "categories/softDeletecategories",
  deletecategories: ROOTURL + "categories/deletecategories",
  // post
  addpost: ROOTURL + "post/addpost",
  listpost: ROOTURL + "post/listpost",
  viewpost: ROOTURL + "post/viewpost",
  updatepost: ROOTURL + "post/updatepost",
  softDeletepost: ROOTURL + "post/softDeletepost",
  deletepost: ROOTURL + "post/deletepost",
  //selling
  addsellingrequest: ROOTURL + "sellingrequest/addsellingrequest",
  listsellingrequest: ROOTURL + "sellingrequest/listsellingrequest",
  viewsellingrequest: ROOTURL + "sellingrequest/viewsellingrequest",
  orderconfirm: ROOTURL + "sellingrequest/orderconfirm",
  sellinginprogress: ROOTURL + "sellingrequest/sellinginprogress",
  sellingrequestwaiting: ROOTURL + "sellingrequest/sellingrequestwaiting",

  listDashBoardCount: ROOTURL + "dashboard/listDashBoardCount",
};

const ImportedURL = {
  API: API,
  LIVEURL: LIVEURL,
};
export default ImportedURL;
