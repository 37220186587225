import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImportedURL from '../../common/api';
import axios from 'axios';
import Select from 'react-select';
import { Success, Error } from '../../common/swal';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Spinner from 'react-bootstrap/Spinner';
import stateList from './state.json';
import { Phonenumber } from '../../common/validate';
import imagepath from "../../../src/assets/images/fakeprofile.jpg"
import { Emailvalidate, Imagevalidation } from '../../common/validate';
import { AC_HANDLE_USERCATEGORY_CHANGE, AC_LIST_USERCATEGORY_DETAIL, AC_EMPTY_USERCATEGORY_FEES_SPINNER, AC_USERCATEGORY_LIST_SPINNER, AC_VIEW_USERCATEGORY_DETAIL, AC_RESET_USERCATEGORY_DETAIL, AC_EMPTY_USERCATEGORY_DETAIL } from '../../actions/usersAction';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import AgGridPiganationComponent from '../../utilities/AgGridReact/aggridpagination';


const initialState = {
    id: '',
    ccError: false,
    cc: '+1',
    defaultCC: '',
    phonenumberError: false,
    phoneNoPatternError: false,
    emailError: false,
    emailExistValidError: false,
    emailValidError: false,
    phoneNoPatternError: false,
    viewRedirect: false,
    editRedirect: false,
    modalType: "",
    profile: '',
    onerror: '',
    cityError: false,
    nameError: false,
    profileError: false,
    descriptionError: false,
    communicationemailValidError: false,
    columnDefs: [
        {
            headerName: 'Profile', field: 'profile', width: 100, floatingFilter: false, headerClass: 'ag-center-header', pinned: 'left',
            cellRendererFramework: function (params) {
                if (params.data) {
                    return (
                        <div>
                            <img
                                style={{ width: '50px', height: '50px', borderRadius: '0px' }}
                                src={params.data.profile ? ImportedURL.LIVEURL + params.data.profile : "../assets/images/xs/avatar1.jpg"}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = imagepath;
                                }}
                                alt=""
                            />
                        </div>
                    );
                }
            }
        },
        { headerName: 'Name', field: 'name', headerClass: 'ag-center-header', width: 100, floatingFilter: true, pinned: 'left' },
        { headerName: 'User Type', field: 'usertype', headerClass: 'ag-center-header', width: 100, floatingFilter: true, pinned: 'left' },
        // { headerName: 'Seller Type', field: 'sellertype', headerClass: 'ag-center-header', width: 100, floatingFilter: true },
        {
            headerName: 'Email', field: 'email', headerClass: 'ag-center-header', width: 100, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data) {
                    return ` ${params.data.email}`
                }
            },
            cellRenderer: function (params) {
                var email = '';
                if (params.data) {
                    if (params.data.email) {
                        email = params.data ? params.data.email : '';
                    }
                    return `
            <span style="position:absolute; ;cursor: pointer;" data-action-type="Copyemail"> <i class="fa fa-clone mr-2" data-action-type="Copyemail"></i>` + email + `</span>
            `
                }
            }
        },
        {
            headerName: 'Phone Number', field: 'phonenumber', width: 100, cellStyle: { 'text-align': 'center' }, floatingFilter: true, headerClass: 'ag-center-header',
            valueGetter: function (params) {
                if (params.data) {
                    var selectValue = ''
                    if (params.data && params.data.phoneCode.length > 0) {
                        params.data.phoneCode.map((item) => {
                            if (item.code == params.data.cc) {
                                selectValue = item.phonecode
                            }
                            if (item.phonecode == params.data.cc) {
                                selectValue = params.data.cc
                            }
                        })
                    }
                    return params.data.cc + " " + params.data.phonenumber;
                }
            }
        },
        // { headerName: 'Location', field: 'location', headerClass: 'ag-center-header', width: 100, floatingFilter: true },




        {
            headerName: 'Status', width: 100, field: 'status', headerClass: 'ag-center-header', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data) {
                    if (params.data.status) {
                        return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                    } else {
                        return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                    }
                }
            }
        },
        {
            headerName: 'Actions', width: 150, field: 'actions', headerClass: 'ag-center-header', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        // "width": 200,
        // "filter": true,
        // "sortable": true,
        // "resizable": true
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 150,
    },
    perPage: 25,
}

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    handleImageError = (e) => {
        e.target.onerror = null; // Remove the event listener to avoid infinite loop
        e.target.src = imagepath
    };
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
        this.setState({ profile: "" });
        this.props.Resetusercategory();
    }
    handleChangeselect = (e) => {
        this.setState({ ViewSpinner: false })
        const { name, value } = e;
        this.props.HandleInputChange(name, value);
        if (name === 'usertype') {
            this.setState({ usertype: value });
            var usertype = value;
            if (usertype) {
                this.setState({ usertypeError: false })
            } else {
                this.setState({ usertypeError: true })
            }
        }
        if (name === 'sellertype') {
            this.setState({ sellertype: value });
            var sellertype = value;
            if (sellertype) {
                this.setState({ sellertypeError: false })
            } else {
                this.setState({ sellertypeError: true })
            }
        }

    }
    handleOnchange = (e) => {
        // const data = UsersState.Usercategory;
        this.setState({ ViewSpinner: false })
        const usercategoryList = this.props.UsersState.listusercategory;
        const { name, value } = e.target;
        const Error = name + "Error";
        const fieldError = name + "Error";
        this.props.HandleInputChange(name, value);
        this.setState({ [Error]: false });
        // if (this.state.defaultCC) {
        //     this.props.HandleInputChange('cc', this.state.defaultCC);
        // }


        if (name === 'name') {
            this.setState({ name: value });
            var username = value;
            if (username) {
                this.setState({ nameError: false })
            } else {
                this.setState({ nameError: true })
            }
        }
        // if(data.district){
        if (name === 'homeaddress') {
            this.setState({ homeaddress: value });
            var homeaddress = value;
            if (homeaddress) {
                this.setState({ homeaddressError: false })
            } else {
                this.setState({ homeaddressError: true })
            }
        }
        if (name === 'officeaddress') {
            this.setState({ officeaddress: value });
            var officeaddress = value;
            if (officeaddress) {
                this.setState({ officeaddressError: false })
            } else {
                this.setState({ officeaddressError: true })
            }
        }
        // }
        if (name === 'phonenumber') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                this.setState({ [Error]: false });
                if (Phonenumber(val)) {
                    this.setState({ phoneNoPatternError: false })

                } else {
                    this.setState({ phoneNoPatternError: true })
                }
            } else {
                this.setState({ phoneNoPatternError: false })
                this.props.HandleInputChange(name, val);
            }
        }

        if (name === 'email') {
            this.setState({ [fieldError]: false });
            if (Emailvalidate(value)) {
                this.setState({ emailValidError: false });
                let exEmail = usercategoryList && usercategoryList.find(e => {
                    return e.email === value
                });
                if (exEmail) {
                    this.setState({ emailExistValidError: true, emailValidError: false })
                } else {
                    this.setState({ emailExistValidError: false })
                }
            } else {
                this.setState({ emailValidError: true, emailExistValidError: false })
            }

        } else {
            this.setState({ [fieldError]: false, emailValidError: false, emailExistValidError: false })

        }

    }
    submit = () => {
        this.setState({ ViewSpinner: false })
        const { UsersState } = this.props;
        const data = UsersState.Usercategory;
        const usercategoryList = this.props.UsersState.listusercategory;
        let valid = 1
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0
        }
        if (!data.usertype) {
            this.setState({ usertypeError: true });
            valid = 0
        }
        if (!data.sellertype) {
            this.setState({ sellertypeError: true });
            valid = 0
        }
        if (!data.profile) {
            this.setState({ profileError: true });
            valid = 0
        }
        if (!data.phonenumber) {
            valid = 0;
            this.setState({ phonenumberError: true });
        }
        if (this.state.phoneNoPatternError) {
            valid = 0;
        }
        if (!data.cc && this.state.defaultCC == '') {
            valid = 0;
            this.setState({ ccError: true });
        }
        if (!data.email) {
            valid = 0;
            this.setState({ emailError: true });
            this.setState({ emailValidError: false });
            // this.emailinputRef.current.focus();
            let exEmail = usercategoryList && usercategoryList.find(e => {
                return e.email === data.email
            });
            if (exEmail) {
                this.setState({ emailExistValidError: true, emailValidError: false })
                // valid = 0;
                // this.emailinputRef.current.focus();
            } else {
                this.setState({ emailExistValidError: false })
            }
        }
        if (!data.homeaddress) {
            this.setState({ homeaddressError: true });
            valid = 0
        }
        if (!data.officeaddress) {
            this.setState({ officeaddressError: true });
            valid = 0
        }
        // if (!data.state) {
        //     this.setState({ StateError: true });
        //     valid = 0
        // }
        if (valid) {
            var formData = new FormData();
            formData.append("name", data.name);
            formData.append("usertype", data.usertype);
            formData.append("sellertype", data.sellertype);
            formData.append("email", data.email);
            formData.append("profile", data.profile);
            formData.append("phonenumber", data.phonenumber);
            formData.append("address", data.address);
            // formData.append("district", data.district);
            // formData.append("state", data.state);
            formData.append("cc", data.cc);
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addUser, formData)
                    .then((formdata) => {
                        Success(formdata.statusText);
                        this.setState({ save: false });
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        this.props.Emptyusercategory();
                        let btn = document.getElementById("closeModal");
                        btn.click();

                    }).catch(({ response }) => {
                        this.setState({ save: false });
                        if (response.status == 409) {
                            Error('Email Already Exist')
                        } else {
                            Error(response.statusText)
                        }

                    });
            } else {
                this.setState({ save: true });
                axios.post(ImportedURL.API.updateUser + '/' + data._id, formData)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.Resetusercategory();
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        this.setState({ Redirect: true })
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });

            }
        }
    }

    uploadhandleChange = e => {
        // this.setState({ ViewSpinner: false })
        // const { name, value } = e.target;
        // if (name === 'logo') {
        //     this.setState({ logo: e.target.files[0], logoError: false })
        // }
        // this.props.HandleInputChange(name, e.target.files[0]);
        //  if (name == 'logo') {
        const imgvalidate = Imagevalidation(e.target.files[0]);
        if (imgvalidate) {
            this.setState({ profileError: false })
            this.setState({ profile: e.target.files[0] });
            var reader = new FileReader();
            var url = reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = function (e) {
                this.setState({
                    profileSrc: [reader.result]
                })
            }.bind(this);
            this.props.HandleInputChange('profile', e.target.files[0]);
        } else {
            this.setState({ name: '' });
            Error('Invalid file extension');
            // alert('Invalid file extension')


        }
        // }


    }
    handlePhoneCode = e => {
        const { name, value } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false, defaultCC: '' });
        this.props.HandleInputChange(name, value);
    }
    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ email: event.data.email, ccError: false, modalType: "Edit", id: event.data._id, sellertypeError: false, phonenumberError: false, usertypeError: false, phoneNoPatternError: false, profileError: false, addressError: false, emailError: false })
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.ViewSpinner();
            this.props.Viewusercategory(event.data._id);
        }
        if (value === 'Copyemail') {
            navigator.clipboard.writeText(event.data.email);
            Swal.fire({ title: 'Copied', showConfirmButton: false, timer: 500 })
        }
        if (value === 'View') {
            this.setState({ viewRedirect: true, id: event.data._id });
            this.props.ViewSpinner();
            this.props.Viewusercategory(event.data._id);
        }

        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.deleteUser + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'users' })
                        .then((data) => {
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    componentDidMount() {
        this.props.ListCountry();

    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ["name", "user type", "seller type", "email", 'phonenumber',],
            fileName: 'users.csv',
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    exportPDF = () => {
        const { UsersState, CountryState } = this.props;
        const phoneCode = CountryState.listcountry;
        const exportData = UsersState.listusercategory;
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 10;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(18);
        const title = "Users";
        const headers = [['#', "Name", "User Type", "Seller Type", "Email", 'Phonenumber',]];
        var data = []
        exportData.map((elt, i) => {
            let ccData = '';
            if (phoneCode.length > 0) {
                phoneCode.map(item => {
                    if (item.code === elt.cc) {
                        ccData = item.phonecode
                    }
                    if (item.phonecode === elt.cc.phonecode) {
                        ccData = item.phonecode
                    }
                })
            }
            data.push(
                [
                    i + 1,
                    elt.name,
                    elt.usertype,
                    elt.sellertype,
                    elt.email,
                    (ccData ? ccData : '') + ' ' + elt.phonenumber,
                    elt.address,
                ]
            )

        });
        let content = {
            startY: 50,
            head: headers,
            headStyles: { fillColor: [243, 253, 232], textColor: [11, 119, 2], },
            body: data,
            styles: {
                minCellHeight: 30,
                valign: 'middle',
                lineWidth: 0.5,
            },
            margin: {
                left: 10,
                right: 10,
                bottom: 70,
            },
            autoPaging: "text",
        };
        doc.setTextColor(11, 119, 2);
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("users.pdf")
    }
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {

                // Grid params for filter and sort
                const page = params.endRow / this.state.perPage;
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                // Action call for inspection list
                this.props.ListSpinner();
                await this.props.Listusercategory({ ...query, perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {} });
                const rowData = this.props.UsersState.listusercategory;;
                const total = this.props.UsersState.totelusercategories;
                const { CountryState } = this.props;
                const phoneCode = CountryState.listcountry;


                const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>'
                // const editOption = '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>'

                const template =
                    '<div><button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-file-text-o" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
                    // + editOption
                    // + deleteOption
                    + '</div>'
                rowData.forEach(object => {
                    object.actions = template;
                });
                rowData.forEach(object => {
                    object.phoneCode = phoneCode;
                });
                params.successCallback(rowData, total);

            }
        }
    }
    componentDidUpdate(prevProps, prevState) {

        // To set data once grid api is updated
        if (this.gridApi) {
            if (prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
    }
    render() {
        const { UsersState, CountryState } = this.props;
        const phoneCode = CountryState.listcountry;
        const data = UsersState.Usercategory;
        const ViewSpinner = UsersState.ViewSpinner;
        const ListSpinner = UsersState.ListSpinner;
        const rowData = UsersState.listusercategory;
        if (this.state.viewRedirect) return <Redirect to={'/view-users/' + this.state.id} />

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        if (data !== undefined) {
            var imageData = data.profile;
        }
        const UserTypeoptions = [
            { value: 'Seller', label: 'Seller', name: 'usertype' },
            { value: 'Buyer', label: 'Buyer', name: 'usertype' },
        ]
        const Typeoptions = [
            { value: 'Hospital Corprate', label: 'Hospital Corprate', name: 'sellertype' },
            { value: 'Production Company', label: 'Production Company', name: 'sellertype' },
            { value: 'Local Seller', label: 'Local Seller', name: 'sellertype' },

        ]
        let ccData = '';
        if (phoneCode) {
            phoneCode.map(item => {
                if (data.cc) {
                    if (item.code === data.cc) {
                        ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                    if (item.phonecode === data.cc) {
                        ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                }
            });

            return (
                <>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/users-list"><h6 className="highlights_breadcrump">Users List</h6></Link></p>
                        </div>
                        <div>
                            <div className="section-body pt-3">
                                <div className="container-fluid">
                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className='rounded_icon'><i className="fa fa-users mr-2"></i></div>
                                                    <h3 className="card-title">Users List</h3>
                                                    <div class="card-options">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <ul className="nav nav-tabs page-header-tab"></ul>
                                                            <div className="header-action">
                                                                {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => this.changeModal(e)} id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button> */}
                                                            </div>
                                                        </div>
                                                        <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                            <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                            <label className="form-label">Limit</label>
                                                            <div className="form-group">
                                                                <Select
                                                                    onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                                    options={limitOptions}
                                                                    defaultValue={limitOptions[0]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    <AgGridPiganationComponent spinner={ListSpinner} rowData={rowData} state={this.state} onRowClicked={(e) => this.onRowClicked(e)} onGridReady={(e) => this.onGridReady(e)} />
                                                </div>
                                            </div>
                                        </div>



                                        {/* <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-lg" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}><i className="fa fa-university mr-2"></i>{this.state.modalType} Users</h5>
                                                        <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                                <img src='../../assets/images/cancel.png' />
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className='row'>
                                                            <div className='col-lg-6'>

                                                                <div className='col-lg-12'>
                                                                    <div className="form-group">
                                                                        <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}> *</span></label>
                                                                        <input type="text" className="form-control" placeholder="Name" name="name" onChange={this.handleOnchange}
                                                                            value={data.name}
                                                                        />
                                                                        <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label"> User Type<span className='ml-1' style={{ color: 'red' }}> *</span></label>
                                                                        <Select
                                                                            onChange={this.handleChangeselect}
                                                                            name="usertype"
                                                                            // defaultValue={classoptions[0]}
                                                                            isDisabled={this.state.modalType == "View"}
                                                                            value={data.usertype ? { label: data.usertype } : ""}
                                                                            options={UserTypeoptions}
                                                                        />
                                                                        <div className="invalid-feedback" style={{ display: this.state.usertypeError ? "block" : 'none' }}>User Type is required</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Seller Type<span className='ml-1' style={{ color: 'red' }}> *</span></label>
                                                                        <Select
                                                                            onChange={this.handleChangeselect}
                                                                            name="sellertype "
                                                                            // defaultValue={classoptions[0]}
                                                                            isDisabled={this.state.modalType == "View"}
                                                                            value={data.sellertype ? { label: data.sellertype } : ""}
                                                                            options={Typeoptions}
                                                                        />
                                                                        <div className="invalid-feedback" style={{ display: this.state.sellertypeError ? "block" : 'none' }}>Seller Type  is required</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Email<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='email' ref={this.emailinputRef} onChange={this.handleOnchange}
                                                                            value={data.email}
                                                                            placeholder="Email" />
                                                                        <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
                                                                        <div className="invalid-feedback" style={{ display: this.state.emailValidError ? "block" : 'none' }}>Enter valid email</div>
                                                                        <div className="invalid-feedback" style={{ display: this.state.emailExistValidError ? "block" : 'none' }}>Email is alredy exist</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div>
                                                                        <div className="form-group">
                                                                            <label className="form-label">Logo<span className="ml-1" style={{ color: 'red' }}> *</span></label>
                                                                            <input name="logo" type="file" accept="image/png,image/jpg,image/jpeg" className="form-control" onChange={this.uploadhandleChange} />
                                                                            <div className="invalid-feedback" style={{ display: this.state.logoError ? "block" : 'none' }}>Logo is required</div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            {
                                                                                this.state.modalType == "Add"
                                                                                &&
                                                                                (this.state.logo
                                                                                    ?
                                                                                    <div style={{ width: '200px', height: '100px' }}>
                                                                                        <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.logo ? URL.createObjectURL(this.state.logo) : null} alt='12' />
                                                                                    </div>
                                                                                    : '')
                                                                            }
                                                                            {
                                                                                this.state.modalType == "Edit"
                                                                                    ?
                                                                                    <div className="form-group">
                                                                                        <div style={{ width: '200px', height: '100px' }}>
                                                                                            <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.logo ? URL.createObjectURL(this.state.logo) : ImportedURL.LIVEURL + imageData} alt="" />
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6'>

                                                                <div className='col-lg-12'>
                                                                    <div className="form-group">
                                                                        <div className="row">
                                                                            <div className="col-sm-5 col-md-5 pr-0">
                                                                                <div className="form-group mb-0">
                                                                                    <label className="form-label">
                                                                                        Country Code <span className="ml-1" style={{ color: 'red' }}> *</span>
                                                                                    </label>
                                                                                    <Select
                                                                                        isDisabled={this.state.modalType == "View"}
                                                                                        value={ccData ? ccData : ''}
                                                                                        onChange={this.handlePhoneCode}
                                                                                        options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                                                                            return {
                                                                                                label: item.phonecode,
                                                                                                flag: item.flag,
                                                                                                value: item.code,
                                                                                                name: 'cc'
                                                                                            }
                                                                                        })}
                                                                                        formatOptionLabel={(item) => {
                                                                                            return (
                                                                                                <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                    <div className="invalid-feedback ccerror" style={{ display: this.state.ccError ? "block" : 'none' }}>CC is required</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-7 col-md-7 pl-0">
                                                                                <label className="form-label">
                                                                                    Phone Number<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                                                </label>
                                                                                <div className="form-group mb-0">
                                                                                    <input type="text" className="form-control ml-1" disabled={this.state.modalType == "View"} ref={this.phonenumberinputRef} name='phonenumber' onChange={this.handleOnchange} value={data.phonenumber} placeholder="Phone Number" />
                                                                                </div >
                                                                            </div >
                                                                            <div className="invalid-feedback phoneerror" style={{ display: this.state.phonenumberError ? "block" : 'none' }}>Phone Number is required</div>
                                                                            <div className="invalid-feedback phoneerror" style={{ display: this.state.phoneNoPatternError ? "block" : 'none' }}>Phone Number should contain 10 digits</div>
                                                                        </div >
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Home Address<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                        <textarea className="form-control" placeholder="Home Address" name="homeaddress" onChange={this.handleOnchange}
                                                                            value={data.homeaddress}
                                                                            isDisabled={this.state.modalType == "View"}

                                                                        />
                                                                     
                                                                        <div className="invalid-feedback" style={{ display: this.state.homeaddressError ? "block" : 'none' }}>Home Address is required</div>
                                                                    </div>

                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Office Address<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                        <textarea className="form-control" placeholder="Office Address" name="officeaddress" onChange={this.handleOnchange}
                                                                            value={data.officeaddress}
                                                                            isDisabled={this.state.modalType == "View"}

                                                                        />
                                                                     
                                                                        <div className="invalid-feedback" style={{ display: this.state.officeaddressError ? "block" : 'none' }}>Office Address is required</div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {this.state.modalType == "Edit" ?
                                                        <div className="card-footer text-right mandatory">
                                                            <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                            <div className="" >
                                                                {
                                                                    this.state.saving ?
                                                                        <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                                        :
                                                                        <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                                                }
                                                                <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                                            </div>
                                                        </div>
                                                        : <div className="card-footer text-right mandatory">
                                                            <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                            <div className="" >
                                                                {
                                                                    this.state.saving ?
                                                                        <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                                        :
                                                                        <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                                                }
                                                                <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                                            </div>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>

                                {/* <div id='overlay-model' style={{ display: ViewSpinner ? 'block' : 'none' }}></div>
                                {ViewSpinner ?
                                    <div className='model_loader_poss'>
                                        <img className='loader_img_style_model' src='../../assets/images/recycle loader.gif' />
                                        <Spinner className='spinner_load_model' variant="info" >
                                        </Spinner>
                                    </div>
                                    : ''} */}
                            </div>
                        </div>

                    </div>
                </>
            );
        }
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    CountryState: state.country,
    UsersState: state.users

})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListCountry: AC_LIST_COUNTRY,
        HandleInputChange: AC_HANDLE_USERCATEGORY_CHANGE,
        Listusercategory: AC_LIST_USERCATEGORY_DETAIL,
        Viewusercategory: AC_VIEW_USERCATEGORY_DETAIL,
        Resetusercategory: AC_RESET_USERCATEGORY_DETAIL,
        Emptyusercategory: AC_EMPTY_USERCATEGORY_DETAIL,
        ViewSpinner: AC_EMPTY_USERCATEGORY_FEES_SPINNER,
        ListSpinner: AC_USERCATEGORY_LIST_SPINNER
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
