import React, { Component } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import { Success, Error } from '../../common/swal';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import ImportedURL from '../../common/api';
import { Imagevalidation, onErrorImage } from '../../common/validate';
import { bindActionCreators } from 'redux';
import imagepath from "../../../src/assets/images/fakeprofile.jpg"
import { AC_SETTING_DETAIL, AC_HANDLE_CHANGE, AC_ACCOUNT_DETAILS } from "../../actions/accountAction";
import { Link, Redirect } from 'react-router-dom';


class SiteSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      contact: '',
      cc: '',
      logo: '',
      logoSrc: '',
      nameError: false,
      emailError: false,
      emailPattern: false,
      contactError: false,
      phonePattern: false,
      addressError: false,
      faviconError: false,
      logoError: false,
      imageChanged: false,

      id: ''
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    const Error = name + "Error";
    this.props.AC_HANDLE_CHANGE(name, value);

    if (name === 'name') {
      if (value) {
        this.setState({ [Error]: false });
      } else {
        this.setState({ [Error]: true })
      }
    }

    if (name === 'email') {
      if (value) {
        this.setState({ [Error]: false });
      } else {
        this.setState({ [Error]: true })
      }
    }
    if (name === 'image') {
      this.setState({ imageChanged: true })
      this.setState({ image: e.target.files[0], logoError: false })

    }
  }



  handlePhoneCode = e => {
    const { name, value, label } = e;
    this.props.AC_HANDLE_CHANGE(name, value);
    const Error = name + "Error";
    this.setState({ [Error]: false });
  }

  componentDidMount() {
    this.props.AC_SETTING_DETAIL();
    this.props.AC_LIST_COUNTRY();
    this.props.AC_ACCOUNT_DETAILS();
  }


  submit = () => {
    const { accountState } = this.props;
    var data = accountState.fields;
    let valid = 1

    if (!data.name) {
      this.setState({ nameError: true });
      valid = 0
    }
    if (!data.email) {
      this.setState({ emailError: true });
      valid = 0
    }


    if (!this.state.image && !data.image) {
      this.setState({ logoError: true });
      valid = 0
    }
    if (valid) {
      var _id = this.state.data;
      const formData = new FormData();
      formData.append('id', data._id)
      formData.append('name', data.name)
      formData.append('email', data.email)
      if (this.state.image) {
        formData.append('image', this.state.image);
      } else {
        formData.append('image', data.image);
      }

      axios.post(ImportedURL.API.updateAdmin, formData)
        .then((res) => {
          this.setState({ saving: false, closed: true })
          Success('updated successfully');
          this.props.AC_SETTING_DETAIL();
        }).catch(({ response }) => {
          this.setState({ saving: false })
          if (response.status == 500) {
            Error(response.status + ' Internal Server Error')
          } else if (response.status == 502) {
            Error(response.status + ' Bad Gateway')
          } else if (response.status == 400) {
            Error('Bad request')
          } else {
            Error(response.statusMessage)
          }
        });
    }
  }
  render() {
    const { countryState, accountState } = this.props;
    var phoneData = countryState.listcountry;
    var data = accountState.fields;
    return (
      <>
        <div className="breadcrump">
          <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/site-settings"><h6 className="highlights_breadcrump">Site Settings</h6></Link></p>
        </div>
        <div className="section-body pt-3">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <div className='rounded_icon'><i className="fa fa-gear mr-2 "></i></div>
                    <h3 className="card-title">Site Settings</h3>
                  </div>
                  <div className="card-body">
                    <form id='clear_form'>

                      <div className='row'>
                        <div className="form-group col-lg-6">
                          <label className="form-label">Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <input className="form-control" placeholder="Name" name='name' value={data.name} onChange={this.onChange} />
                          <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                        </div>
                        <div className="form-group col-lg-6">
                          <label className="form-label">Email<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <input className="form-control" name='email' value={data.email} disabled onChange={this.onChange} />
                          <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
                          <div className="invalid-feedback" style={{ display: this.state.emailPattern ? "block" : 'none' }}>Enter valid email</div>
                        </div>
                        <div className="form-group col-lg-6">
                          <label className="form-label">Profile<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                          <input
                            name='image'
                            type="file"
                            onChange={this.onChange}
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <div style={{ width: '200px', height: '100px' }}>
                            <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : ImportedURL.LIVEURL + data.image}
                              onError={onErrorImage}
                              alt="" />
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                  <div className="card-footer text-right">
                    <button type="submit" className="btn btn-primary" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  fixNavbar: state.settings.isFixNavbar,
  countryState: state.country,
  accountState: state.account
})
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    AC_LIST_COUNTRY, AC_SETTING_DETAIL, AC_HANDLE_CHANGE, AC_ACCOUNT_DETAILS
  }, dispatch)

}
export default connect(mapStateToProps, mapDispatchToProps)(SiteSettings);
