import { TestHeadless } from 'ag-grid-community';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ImportedURL from '../../common/api';
import { onErrorImage } from '../../common/validate';
import Swal from 'sweetalert2';


class Header extends Component {
	signOut = (e) => {
		Swal.fire({
			title: 'Are you sure want to logout ?',
			showCancelButton: true,
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok',
			imageUrl: '../../assets/images/signout.png',
			customClass: {
				popup: 'swal_pop',
				title: 'swal_title',
				image: 'swal_image',
				actions: 'swal_action',
				confirmButton: 'swal_confirm',
				cancelButton: 'swal_close',
			}
		}).then((result) => {
			if (result.isConfirmed) {
				e.preventDefault();
				localStorage.removeItem('wastemanagement');
				window.location.href = "/login";
			}
		})
	}
	render() {
		const { fixNavbar, darkHeader, AccountState } = this.props;
		const logo = AccountState.account.image;
		const dataValue = AccountState.account;
		const account = AccountState.account;
		const data = typeof logo == 'string' ? logo : '';

		return (
			<div>
				<div
					id="page_top"
					className={`section-body ${fixNavbar ? "sticky-top" : ""} ${darkHeader ? "top_dark" : ""}`}
				>
					<div className="container-fluid">
						<div className="page-header">
							<div className="left">
								<h1 className="page-title">
									{/* <div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text custom-top-search">
												<i className='fa fa-search'></i>
											</div>
										</div>
										<input type="text" class="form-control custom-input" aria-label="Text input with checkbox" placeholder='Enter Your Keywords' />
									</div> */}
								</h1>
							</div>
							<div className="right">
								<div className="notification d-flex">
									<div className="dropdown d-flex">
										{/* <a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-envelope" />
											<span className="badge badge-primary nav-unread" />
										</a> */}
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<ul className="list-unstyled feeds_widget">
												<li>
													<div className="feeds-left">
														<i className="fa fa-user" />
													</div>
													<div className="feeds-body">
														<h4 className="title">
															New User{' '}
															<small className="float-right text-muted">10:45</small>
														</h4>
														<small>I feel great! Thanks team</small>
													</div>
												</li>
											</ul>
											<div className="dropdown-divider" />
											<Link to="/notifications"
												className="dropdown-item text-center text-muted-dark readall"
											>
												See all notifications
											</Link>
										</div>
									</div>
									{/* <div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-bell" />
											<span className="badge badge-primary nav-unread" />
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<ul className="list-unstyled feeds_widget">
												<li>
													<div className="feeds-left">
														<i className="fa fa-user" />
													</div>
													<div className="feeds-body">
														<h4 className="title">
															New User{' '}
															<small className="float-right text-muted">10:45</small>
														</h4>
														<small>I feel great! Thanks team</small>
													</div>
												</li>
											</ul>
											<div className="dropdown-divider" />
											<Link to="/notifications"
												className="dropdown-item text-center text-muted-dark readall"
											>
												See all notifications
											</Link>
										</div>
									</div> */}
									<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											{
												account.role == 'admin' ?
													<img className="avatar" src={account.image ? ImportedURL.LIVEURL + account.image : ImportedURL.LIVEURL + "../assets/images/xs/avatar2.jpg"} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" onError={onErrorImage}></img>
													:
													<img className="avatar" src={account.logo ? ImportedURL.LIVEURL + account.logo : ImportedURL.LIVEURL + "../assets/images/xs/avatar2.jpg"} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" onError={onErrorImage}></img>
											}
											{/* {dataValue.local ?
											<>
												{
													data ?
														<img className="avatar" src={ImportedURL.LIVEURL + 'uploads/' + data
														} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url"></img>
														:
														<img className="avatar" src={ImportedURL.LIVEURL + "../assets/images/xs/avatar2.jpg"} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url"></img>
												}
											</>
											:
											<>
												{
													data ?
														<img className="avatar" src={ImportedURL.FILEURL + data
														} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url"></img>
														:
														<img className="avatar" src={ImportedURL.LIVEURL + "../assets/images/xs/avatar2.jpg"} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url"></img>
												}
											</>
											} */}
											{/* <img class="avatar" src="../assets/images/xs/avatar2.jpg" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url"></img> */}
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<Link to="/site-settings" className="dropdown-item">
												<i className="dropdown-icon fe fe-settings" /> Settings
											</Link>
											<div to="/login" className="dropdown-item" onClick={this.signOut}>
												<i className="dropdown-icon fe fe-log-out" /> Sign out
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar,
	darkHeader: state.settings.isDarkHeader,
	AccountState: state.account,

})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Header);