import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_USERCATEGORY_DETAIL, AC_RESET_USERCATEGORY_DETAIL, AC_LIST_USERCATEGORY_DETAIL, AC_HANDLE_USERCATEGORY_CHANGE } from '../../actions/usersAction';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import ImportedURL from '../../common/api';
import moment from 'moment';
import Select from 'react-select';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Error, Success } from '../../common/swal';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import imagepath from "../../../src/assets/images/fakeprofile.jpg"
import { onErrorImage } from '../../common/validate';



class ViewUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            pancard: false,
            shoplicense: false,
            drivinglicense: false,
            show: false,
            rejactError: false,
            smShow: false,
            rejectedreason: '',
            rejecteddate: '',
        };
    }
    handleclear = e => {
        this.props.AC_RESET_USERCATEGORY_DETAIL();
    }
    handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;

        const aadharreason = value;
        if (value) {
            this.setState({ rejactError: false })
        } else {
            this.setState({ rejactError: true })
        }
        this.props.AC_HANDLE_USERCATEGORY_CHANGE(name, value);
    }
    handleChangeselect = (e) => {
        this.setState({ ViewSpinner: false })
        const { name, value } = e;
        if (value == "Rejected") {
            this.setState({ show: true })
        }
        this.props.AC_HANDLE_USERCATEGORY_CHANGE(name, value);
        this.props.AC_HANDLE_USERCATEGORY_CHANGE('type', name);
        this.props.AC_HANDLE_USERCATEGORY_CHANGE('value', value);
    }

    // UpdateStatus = (data) => {
    //     axios.post(ImportedURL.API.documentverification + '/' + this.state.id, data)
    //         .then((res) => {
    //             // Success("updated documents status successfully");
    //             this.props.AC_VIEW_USERCATEGORY_DETAIL(this.state.id)
    //         }).catch(({ response }) => {
    //             Error(response.statusText)
    //             this.setState({ save: false });
    //         });

    // }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.setState({ id });
        this.props.AC_VIEW_USERCATEGORY_DETAIL(id)
        this.props.AC_LIST_COUNTRY();
    }
    handleClose = () => {
        const { UsersState } = this.props;
        const data = UsersState.Usercategory;
        let valid = 1
        if (!data.aadharreason) {
            this.setState({ rejactError: true });
            valid = 0
        }
        axios.post(ImportedURL.API.documentverification + '/' + this.state.id, data)
            .then((res) => {
                Success("updated documents status successfully");
                this.props.AC_VIEW_USERCATEGORY_DETAIL(this.state.id)
            }).catch(({ response }) => {
                Error(response.statusText)
                this.setState({ save: false });
            });
        this.setState({ show: false });
    };
    setSmShow = (value) => {
        this.setState({ smShow: value });
    };
    handle = () => {
        this.setSmShow(true)
        const { UsersState } = this.props;
        const data = UsersState.Usercategory;
        if (data.aadharreason) {
            this.setState({ rejectedreason: data.aadharreason })
            this.setState({ rejecteddate: data.aadharrejecteddate })
        }

    }
    pancardhandle = () => {
        this.setSmShow(true)
        const { UsersState } = this.props;
        const data = UsersState.Usercategory;
        if (data.pancardreason) {
            this.setState({ rejectedreason: data.pancardreason })
            this.setState({ rejecteddate: data.panrejecteddate })

        }
    }
    shopdhandle = () => {
        this.setSmShow(true)
        const { UsersState } = this.props;
        const data = UsersState.Usercategory;
        if (data.shoplicensereason) {
            this.setState({ rejectedreason: data.shoplicensereason })
            this.setState({ rejecteddate: data.shoprejecteddate })

        }
    }
    driverhandle = () => {
        this.setSmShow(true)
        const { UsersState } = this.props;
        const data = UsersState.Usercategory;
        if (data.drivinglicensereason) {
            this.setState({ rejectedreason: data.drivinglicensereason })
            this.setState({ rejecteddate: data.drivingrejecteddate })

        }
    }
    handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = imagepath;
    }
    render() {
        const { UsersState, CountryState, } = this.props;
        const { photoIndex, isOpen, pancard, shoplicense, drivinglicense, smShow, rejectedreason, rejecteddate } = this.state;
        const phoneData = CountryState.listcountry;
        const data = UsersState.Usercategory;
        const list = UsersState.listusercategory;

        const Aadharoption = [
            { value: 'Inprogress', label: "Inprogress", name: 'aadharcardstatus' },
            { value: 'Verified', label: "Verified", name: 'aadharcardstatus' },
            { value: 'Rejected', label: "Rejected", name: 'aadharcardstatus' },
        ]
        const pancardoption = [
            { value: 'Inprogress', label: "Inprogress", name: 'pancardstatus' },
            { value: 'Verified', label: "Verified", name: 'pancardstatus' },
            { value: 'Rejected', label: "Rejected", name: 'pancardstatus' },
        ]
        const shoplicenseoption = [
            { value: 'Inprogress', label: "Inprogress", name: 'shoplicensestatus' },
            { value: 'Verified', label: "Verified", name: 'shoplicensestatus' },
            { value: 'Rejected', label: "Rejected", name: 'shoplicensestatus' },
        ]
        const drivinglicenseoption = [
            { value: 'Inprogress', label: "Inprogress", name: 'drivinglicensestatus' },
            { value: 'Verified', label: "Verified", name: 'drivinglicensestatus' },
            { value: 'Rejected', label: "Rejected", name: 'drivinglicensestatus' },
        ]

        let coutntryCode = ''
        phoneData.map((item) => {
            coutntryCode = item.phonecode
        })

        const imagesarray = []
        if (data.adharcard) {
            imagesarray.push(
                ImportedURL.LIVEURL + data.adharcard
            )
        }
        if (data.pancard) {
            imagesarray.push(
                ImportedURL.LIVEURL + data.pancard
            )

        }
        if (data.shoplicense) {
            imagesarray.push(
                ImportedURL.LIVEURL + data.shoplicense
            )
        }
        if (data.drivinglicense) {
            imagesarray.push(
                ImportedURL.LIVEURL + data.drivinglicense
            )
        }
        const aadharimages = [ImportedURL.LIVEURL + data.adharcard]
        const pancardimage = [ImportedURL.LIVEURL + data.pancard]
        const shoplicenseimages = [ImportedURL.LIVEURL + data.shoplicense]
        const drivinglicenseimages = [ImportedURL.LIVEURL + data.drivinglicense]
        // const rejectedreason= [];
        // if(data.aadharreason){
        //     rejectedreason.push(data.aadharreason)
        // }
        // if(data.pancardreason){
        //     rejectedreason.push(data.pancardreason)
        // }
        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/users-list"><h6>Users List </h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump">View Users</h6></p>
                </div>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Project-OnGoing" role="tabpanel">
                                <div className='row'>
                                    <div className="col-lg-12   col-md-12">
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className="card ">
                                                    <div className="card-header">
                                                        <div className='rounded_icon'>
                                                            <i className="fa fa-users mr-2"></i>
                                                        </div>
                                                        <h3 className="card-title">View Users</h3>
                                                        <div class="card-options">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <ul className="nav nav-tabs page-header-tab"></ul>

                                                                <div className="header-action">
                                                                    <Link to='/users-list'>
                                                                        <button type="button" onClick={this.handleclear} className="btn btn-round btn-primary">
                                                                            <i className="fa fa-solid fa-chevron-left mr-2"></i>Back
                                                                        </button>
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div class="row clearfix">
                                                            <div class="col-lg-4 col-md-12">
                                                                <ul class="list-group mb-3">
                                                                    <li class="list-group-item">
                                                                        <div class="media mb-0">
                                                                            <img class="rounded mr-3" style={{ width: '60px', height: '60px', borderRadius: '0px' }} src={data.profile ? ImportedURL.LIVEURL + data.profile : "../assets/images/xs/avatar2.jpg"} alt="" onError={onErrorImage} />
                                                                            <div class="media-body">
                                                                                <h5 class="m-0">{data.name ? data.name : '----'}</h5>
                                                                                <p class="m-0">{data.usertype ? data.usertype : '----'}</p>

                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li class="list-group-item">
                                                                        <small class="text-muted">Email: </small>
                                                                        <p class="mb-0">{data.email ? data.email : '----'}</p>
                                                                    </li>
                                                                    <li class="list-group-item">
                                                                        <small class="text-muted">Home Address: </small>
                                                                        <p class="mb-0">{data.homeaddress ? data.homeaddress : '----'}</p>
                                                                    </li><li class="list-group-item">
                                                                        <small class="text-muted">Office Address: </small>
                                                                        <p class="mb-0">{data.officeaddress ? data.officeaddress : '----'}</p>
                                                                    </li>
                                                                    <li class="list-group-item">
                                                                        <small class="text-muted">Phone Number: </small>
                                                                        <p class="mb-0">{coutntryCode} {data.phonenumber}</p>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                            <div class="col-lg-8 col-md-12">
                                                                <div class="card">
                                                                    <div class="card-header">
                                                                        <h3 class="card-title">Verification Documents</h3>
                                                                    </div>
                                                                    <div class="card-body">
                                                                        <div class="row ">
                                                                            <div class="col">
                                                                                <div class="form-group">
                                                                                    <div>
                                                                                        <p>Aadhar Card <i class="fa fa-commenting" onClick={this.handle} aria-hidden="true" style={{ cursor: 'pointer', fontSize: '19px !important' }}></i></p>
                                                                                        <Select
                                                                                            onChange={this.handleChangeselect}
                                                                                            name="aadharcardstatus"
                                                                                            defaultValue={Aadharoption[0]}
                                                                                            isDisabled={this.state.modalType == "View"}
                                                                                            value={data.aadharcardstatus ? { label: data.aadharcardstatus } : ""}
                                                                                            options={Aadharoption}

                                                                                        />

                                                                                    </div>
                                                                                    <div className="col-7 py-1">{<img style={{ width: '300px', height: '150px', borderRadius: '0px' }} src={data.adharcard ? ImportedURL.LIVEURL + data.adharcard : "no data"} alt="no data"
                                                                                        onClick={() => this.setState({ isOpen: true })}
                                                                                        onError={onErrorImage}
                                                                                    />}</div>

                                                                                    <div>
                                                                                        {isOpen && (
                                                                                            <Lightbox
                                                                                                mainSrc={aadharimages[photoIndex]}
                                                                                                // nextSrc={imagesarray[(photoIndex + 1) % imagesarray.length]}
                                                                                                // prevSrc={imagesarray[(photoIndex + imagesarray.length - 1) % imagesarray.length]}
                                                                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                                                                            // onMovePrevRequest={() =>
                                                                                            //     this.setState({
                                                                                            //         photoIndex: (photoIndex + imagesarray.length - 1) % imagesarray.length,
                                                                                            //     })
                                                                                            // }
                                                                                            // onMoveNextRequest={() =>
                                                                                            //     this.setState({
                                                                                            //         photoIndex: (photoIndex + 1) % imagesarray.length,
                                                                                            //     })
                                                                                            // }
                                                                                            />
                                                                                        )}
                                                                                    </div>

                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <p>Pan Card <i class="fa fa-commenting" onClick={this.pancardhandle} aria-hidden="true" style={{ cursor: 'pointer', fontSize: '19px !important' }}></i></p>
                                                                                    <Select
                                                                                        onChange={this.handleChangeselect}
                                                                                        name="pancardstatus"
                                                                                        defaultValue={pancardoption[0]}
                                                                                        isDisabled={this.state.modalType == "View"}
                                                                                        value={data.pancardstatus ? { label: data.pancardstatus } : ""}
                                                                                        options={pancardoption}
                                                                                    />
                                                                                    {/* <a href={ImportedURL.LIVEURL + data.pancard} target="_blank">
                                                                                        <div className="col-7 py-1">{<img style={{ width: '300px', height: '150px', borderRadius: '0px' }} src={data.pancard ? ImportedURL.LIVEURL + data.pancard : "no data"} alt="no data" />}</div>
                                                                                    </a> */}
                                                                                    <div className="col-7 py-1">{<img style={{ width: '300px', height: '150px', borderRadius: '0px' }} src={data.pancard ? ImportedURL.LIVEURL + data.pancard : "no data"} alt="no data"
                                                                                        onClick={() => this.setState({ pancard: true })}
                                                                                        onError={onErrorImage}
                                                                                    />}</div>

                                                                                    <div>
                                                                                        {pancard && (
                                                                                            <Lightbox
                                                                                                mainSrc={pancardimage[photoIndex]}
                                                                                                // nextSrc={imagesarray[(photoIndex + 1) % imagesarray.length]}
                                                                                                // prevSrc={imagesarray[(photoIndex + imagesarray.length - 1) % imagesarray.length]}
                                                                                                onCloseRequest={() => this.setState({ pancard: false })}
                                                                                            // onMovePrevRequest={() =>
                                                                                            //     this.setState({
                                                                                            //         photoIndex: (photoIndex + imagesarray.length - 1) % imagesarray.length,
                                                                                            //     })
                                                                                            // }
                                                                                            // onMoveNextRequest={() =>
                                                                                            //     this.setState({
                                                                                            //         photoIndex: (photoIndex + 1) % imagesarray.length,
                                                                                            //     })
                                                                                            // }
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div class="col ">
                                                                                {data.usertype == "Driver" ?
                                                                                    <div class="form-group">
                                                                                        <p>Driving License <i class="fa fa-commenting" onClick={this.driverhandle} aria-hidden="true" style={{ cursor: 'pointer', fontSize: '19px !important' }}></i></p>
                                                                                        <Select
                                                                                            onChange={this.handleChangeselect}
                                                                                            name="drivinglicensestatus"
                                                                                            defaultValue={drivinglicenseoption[0]}
                                                                                            isDisabled={this.state.modalType == "View"}
                                                                                            value={data.drivinglicensestatus ? { label: data.drivinglicensestatus } : ""}
                                                                                            options={drivinglicenseoption}
                                                                                        />
                                                                                        {/* <a href={ImportedURL.LIVEURL + data.drivinglicense} target="_blank">
                                                                                        <div className="col-7 py-1">{<img style={{ width: '300px', height: '150px', borderRadius: '0px' }} src={data.drivinglicense ? ImportedURL.LIVEURL + data.drivinglicense : "no data"} alt="no data" />}</div>
                                                                                    </a> */}
                                                                                        <div className="col-7 py-1">{<img style={{ width: '300px', height: '150px', borderRadius: '0px' }} src={data.drivinglicense ? ImportedURL.LIVEURL + data.drivinglicense : "no data"} alt="no data"
                                                                                            onClick={() => this.setState({ drivinglicense: true })}
                                                                                            onError={onErrorImage}
                                                                                        />}</div>

                                                                                        <div>
                                                                                            {drivinglicense && (
                                                                                                <Lightbox
                                                                                                    mainSrc={drivinglicenseimages[photoIndex]}
                                                                                                    // nextSrc={imagesarray[(photoIndex + 1) % imagesarray.length]}
                                                                                                    // prevSrc={imagesarray[(photoIndex + imagesarray.length - 1) % imagesarray.length]}
                                                                                                    onCloseRequest={() => this.setState({ drivinglicense: false })}
                                                                                                // onMovePrevRequest={() =>
                                                                                                //     this.setState({
                                                                                                //         photoIndex: (photoIndex + imagesarray.length - 1) % imagesarray.length,
                                                                                                //     })
                                                                                                // }
                                                                                                // onMoveNextRequest={() =>
                                                                                                //     this.setState({
                                                                                                //         photoIndex: (photoIndex + 1) % imagesarray.length,
                                                                                                //     })
                                                                                                // }
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    </div> :
                                                                                    <div class="form-group">
                                                                                        <p>Shop License<i class="fa fa-commenting" onClick={this.shopdhandle} aria-hidden="true" style={{ cursor: 'pointer', fontSize: '19px !important' }}></i></p>
                                                                                        <Select
                                                                                            onChange={this.handleChangeselect}
                                                                                            name="shoplicensestatus"
                                                                                            defaultValue={shoplicenseoption[0]}
                                                                                            isDisabled={this.state.modalType == "View"}
                                                                                            value={data.shoplicensestatus ? { label: data.shoplicensestatus } : ""}
                                                                                            options={shoplicenseoption}
                                                                                        />
                                                                                        {/* <a href={ImportedURL.LIVEURL + data.shoplicense} target="_blank">
                                                                                        <div className="col-7 py-1">{<img style={{ width: '300px', height: '150px', borderRadius: '0px' }} src={data.shoplicense ? ImportedURL.LIVEURL + data.shoplicense : "no data"} alt="no data" />}</div>
                                                                                    </a> */}
                                                                                        <div className="col-7 py-1">{<img style={{ width: '300px', height: '150px', borderRadius: '0px' }} src={data.shoplicense ? ImportedURL.LIVEURL + data.shoplicense : "no data"} alt="no data"
                                                                                            onClick={() => this.setState({ shoplicense: true })}
                                                                                            onError={onErrorImage}
                                                                                        />}</div>

                                                                                        <div>
                                                                                            {shoplicense && (
                                                                                                <Lightbox
                                                                                                    mainSrc={shoplicenseimages[photoIndex]}
                                                                                                    // nextSrc={imagesarray[(photoIndex + 1) % imagesarray.length]}
                                                                                                    // prevSrc={imagesarray[(photoIndex + imagesarray.length - 1) % imagesarray.length]}
                                                                                                    onCloseRequest={() => this.setState({ shoplicense: false })}
                                                                                                // onMovePrevRequest={() =>
                                                                                                //     this.setState({
                                                                                                //         photoIndex: (photoIndex + imagesarray.length - 1) % imagesarray.length,
                                                                                                //     })
                                                                                                // }
                                                                                                // onMoveNextRequest={() =>
                                                                                                //     this.setState({
                                                                                                //         photoIndex: (photoIndex + 1) % imagesarray.length,
                                                                                                //     })
                                                                                                // }
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* model */}
                                                    <Modal show={this.state.show} onHide={this.handleClose}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Document Reject</Modal.Title>
                                                        </Modal.Header>
                                                        <div className="modal-body">
                                                            <div className="row clearfix">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Reason of Reject<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                        <textarea className="form-control" name='aadharreason' onChange={this.handleChange}
                                                                            //  value={data.aadharreason} 
                                                                            placeholder="Reason of Reject"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body> */}
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={this.handleClose}>
                                                                Close
                                                            </Button>
                                                            <Button variant="primary" style={{ fontSize: "15px" }} onClick={this.handleClose}>
                                                                Send mail
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>

                                                    {/* message show model */}
                                                    <Modal
                                                        size="sm"
                                                        show={smShow}
                                                        onHide={() => this.setSmShow(false)}
                                                        aria-labelledby="example-modal-sizes-title-sm"
                                                    >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title id="example-modal-sizes-title-sm">
                                                                Rejected Message
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-4 py-1"><strong>Rejected Reason</strong></div>
                                                                    <div className="col-1 py-1"><strong>:</strong></div>
                                                                    <div className="col-7 py-1">{rejectedreason}</div>

                                                                    <div className="col-4 py-1"><strong>Rejected Date</strong></div>
                                                                    <div className="col-1 py-1"><strong>:</strong></div>
                                                                    <div className="col-7 py-1">{rejecteddate ? rejecteddate : '-------'}</div>

                                                                </div>
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    CountryState: state.country,
    UsersState: state.users
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        AC_VIEW_USERCATEGORY_DETAIL,
        AC_RESET_USERCATEGORY_DETAIL,
        AC_LIST_COUNTRY,
        Listusercategory: AC_LIST_USERCATEGORY_DETAIL,
        AC_HANDLE_USERCATEGORY_CHANGE
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewUsers);