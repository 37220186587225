const initialState = {
    listSubcategory: [],
    totelSubCategories:0,
    Subcategory: {
        categoryname:'',
        subcategoryname: '',
        image: '',
    },
    ViewSpinner: false,
    ListSpinner: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_SUBCATEGORY_CHANGE':
            return Object.assign({}, state, {
                Subcategory: {
                    ...state.Subcategory,
                    [action.name]: action.value
                }
            })
            case 'RESET_SUBCATEGORY_DETAIL':
                return Object.assign({}, state, {
                    Subcategory: initialState.Subcategory,
                })
                case 'EMPTY_SUBCATEGORY_FEES_SPINNER':
                    return Object.assign({}, state, {
                        ViewSpinner: true
                    })
                    case 'SUBCATEGORY_LIST_SPINNER':
                        return Object.assign({}, state, {
                            ListSpinner: true
                        })
            case 'LIST_SUBCATEGORY_DETAIL':
                return {
                    ...state,
                    listSubcategory: action.payload,
                    totelSubCategories: action.total,
                    ListSpinner: false,
                }
            case 'VIEW_SUBCATEGORY_DETAIL':
                return Object.assign({}, state, {
                    Subcategory: action.payload,
                    ViewSpinner: false,
                })
    
            case 'EMPTY_SUBCATEGORY_DETAIL':
                return Object.assign({}, state, {
                    Subcategory: {
                        categoryname:'',
                        subcategoryname: '',
                        image: '',
                        
                    },
                })
        
        default:
            return state;
    }
}