import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_CATEGOIRES_DETAIL, AC_RESET_CATEGOIRES_DETAIL } from '../../actions/categoriesAction';
import ImportedURL from '../../common/api';
import imagepath from "../../../src/assets/images/noimages.png"
import { onErrorImage } from '../../common/validate';


class Viewcategories extends Component {
    handleclear = e => {
        this.props.AC_RESET_CATEGOIRES_DETAIL();
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.AC_VIEW_CATEGOIRES_DETAIL(id)
    }

    render() {
        const { CategoriesState } = this.props;
        const data = CategoriesState.categories;
        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-categories"><h6>Categories List </h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump">View Categories</h6></p>
                </div>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Project-OnGoing" role="tabpanel">
                                <div className='row'>
                                    <div className="col-lg-12   col-md-12">
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className="card ">
                                                    <div className="card-header">
                                                        <div className='rounded_icon'>
                                                            <i className="icon-bar-chart mr-2 "></i>
                                                        </div>
                                                        <h3 className="card-title">View Categories</h3>
                                                        <div class="card-options">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <ul className="nav nav-tabs page-header-tab"></ul>

                                                                <div className="header-action">
                                                                    <Link to='/list-categories'>
                                                                        <button type="button" onClick={this.handleclear} className="btn btn-round btn-primary">
                                                                            <i className="fa fa-solid fa-chevron-left mr-2"></i>Back
                                                                        </button>
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-4 py-1"><strong>Image</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{<img style={{ width: '40px', height: '40px', borderRadius: '0px' }}
                                                                onError={onErrorImage}
                                                                src={ImportedURL.LIVEURL + data.image} alt=""
                                                            />}
                                                            </div>

                                                            <div className="col-4 py-1"><strong>Main category</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.maincategory}</div>

                                                            <div className="col-4 py-1"><strong>Sub category</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.subcategory}</div>

                                                            <div className="col-4 py-1"><strong>Categories</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.categories}</div>


                                                            <div className="col-4 py-1"><strong>Quantity</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1"> {data.quantity}</div>

                                                            <div className="col-4 py-1"><strong>Market Price</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.marketprice}</div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    CategoriesState: state.Categories,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ AC_VIEW_CATEGOIRES_DETAIL, AC_RESET_CATEGOIRES_DETAIL }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Viewcategories);