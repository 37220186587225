import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_MAINCATEGORY_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_MAINCATEGORY_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_MAINCATEGORY_DETAIL() {
    return function (dispatch) {
        dispatch({ type: "RESET_MAINCATEGORY_DETAIL" })
    };
}
export function AC_EMPTY_MAINCATEGORY_FEES_SPINNER() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_MAINCATEGORY_FEES_SPINNER" })
    }
}
export function AC_MAINCATEGORY_LIST_SPINNER() {
    return function (dispatch) {
        dispatch({ type: "MAINCATEGORY_LIST_SPINNER" })
    }
}
export function AC_LIST_MAINCATEGORY_DETAIL(params = {}) {
    return  function (dispatch) {
        return (
             axios.post(ImportedURL.API.listCategory,params)
                .then((res) => {
                    dispatch({ type: "LIST_MAINCATEGORY_DETAIL", payload: res.data.data, total: res.data.total, spinner: false })
                }).catch((err) => { console.log(err); })
        )
    };
}

export function AC_VIEW_MAINCATEGORY_DETAIL(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewCategory + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_MAINCATEGORY_DETAIL", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}


export function AC_EMPTY_MAINCATEGORY_DETAIL() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_MAINCATEGORY_DETAIL" })
    }
}