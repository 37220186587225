import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { onErrorImage } from '../../common/validate';

class SpinnerComponent extends Component {
    render() {
        const { spinner, overlay } = this.props;
        return (
            <>
                {overlay === false ? '' : <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>}
                {spinner ?
                    <div className='common_loader'>
                        <img className='loader_img_style_common' src='../assets/images/recycle_loader.gif' onError={onErrorImage} />
                        <Spinner className='spinner_load_common' variant="info" >
                        </Spinner>
                    </div>
                    : ""}
            </>
        );
    }
}

export default SpinnerComponent;