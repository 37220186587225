import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Success, Error } from '../../common/swal';
import axios from 'axios';
import ImportedUrl from '../../common/api';
import { onErrorImage } from '../../common/validate';
import ErrorResponseComponent from '../../utilities/ErrorStatus/errorresponse';

export default class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			emailError: false,
		}
	}
	onChange = e => {
		const { name, value } = e.target;
		const Error = name + "Error";
		this.setState({ [Error]: false, [name]: value });
	}
	submit = (e) => {
		e.preventDefault()
		const { email } = this.state;
		if (!email) this.setState({ emailError: true });
		if (email) {
			const formData = { email: email }
			this.setState({ saving: true })
			axios.post(ImportedUrl.API.forgotPassword, formData)
				.then((res) => {
					this.setState({ saving: false })
					Success('Check mail for new password');
					setTimeout(() => { window.location.href = "/" }, 2000);
				}).catch(({ response }) => {
					this.setState({ saving: false })
					ErrorResponseComponent(response)
				});
		}
	}
	render() {
		return (
			<div className="auth">
				<div className="auth_left">
					<div className="card">
						<div className="text-center mb-2" style={{ width: '100px', height: '100px', margin: ' 0 auto' }}>
							<Link className="header-brand" to="/">
								{/* <i className="fe fe-command brand-logo" /> */}
								<img className="avatar w-100 login_logo" src="../assets/images/newlogo.png" onError={onErrorImage} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" style={{ height: '100%', objectFit: 'cover' }} />
							</Link>
						</div>
						<div className="card-body">
							<div className="card-title">Forgot password</div>
							<p className="text-muted">
								Enter your email address and your password will be reset and emailed to you.
							</p>
							<div className="form-group">
								<label className="form-label" htmlFor="exampleInputEmail1">
									Email<span className="ml-1" style={{ color: 'red' }}>*</span>
								</label>
								<input
									type="email"
									className="form-control"
									id="exampleInputEmail1"
									aria-describedby="emailHelp"
									placeholder="Enter Email"
									onChange={this.onChange}
									name="email"
								/>
								<div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
							</div>
							<div className="form-footer">
								{
									this.state.saving
										?
										<button className="btn btn-primary btn-block" ><i className="fa fa-spinner fa-spin mr-2"></i>Sending</button>
										:
										<button className="btn btn-primary btn-block" onClick={this.submit}><i className="fa fa-paper-plane mr-2"></i>Send</button>
								}
							</div>
						</div>
						<div className="text-center text-muted">
							Forget it, <Link to="/login">Back</Link> to the Sign in screen.
						</div>
					</div>
				</div>
				<div className="auth_right">
					<div className="carousel slide" data-ride="carousel" data-interval={3000}>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img src="assets/images/slider1.svg" className="img-fluid" alt="login page" onError={onErrorImage} />
								<div className="px-4 mt-4">
									<h4>Fully Responsive</h4>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/slider2.svg" className="img-fluid" alt="login page" onError={onErrorImage} />
								<div className="px-4 mt-4">
									<h4>Quality Code and Easy Customizability</h4>
									<p>There are many variations of passages of Lorem Ipsum available.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src="assets/images/slider3.svg" className="img-fluid" alt="login page" onError={onErrorImage} />
								<div className="px-4 mt-4">
									<h4>Cross Browser Compatibility</h4>
									<p>Overview We're a group of women who want to learn JavaScript.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
