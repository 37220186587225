const initialState = {
    listpost: [],
    totelpost: 0,
    post: {
        categoryname: '',
        subcategoryname: '',
        categories: '',
        image: '',
        price: '',
        requiredquantity: '',
        deliveryoption: '',

    },
    ViewSpinner: false,
    ListSpinner: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_POST_CHANGE':
            return Object.assign({}, state, {
                post: {
                    ...state.post,
                    [action.name]: action.value
                }
            })
        case 'RESET_POST_DETAIL':
            return Object.assign({}, state, {
                post: initialState.post,
            })
        case 'EMPTY_POST_FEES_SPINNER':
            return Object.assign({}, state, {
                ViewSpinner: true
            })
        case 'POST_LIST_SPINNER':
            return Object.assign({}, state, {
                ListSpinner: true
            })
        case 'LIST_POST_DETAIL':
            return {
                ...state,
                listpost: action.payload,
                totelpost: action.total,
                ListSpinner: false,
            }
        case 'VIEW_POST_DETAIL':
            return Object.assign({}, state, {
                post: action.payload,
                ViewSpinner: false,
            })

        case 'EMPTY_POST_DETAIL':
            return Object.assign({}, state, {
                post: {
                    categoryname: '',
                    subcategoryname: '',
                    categories: '',
                    image: '',
                    price: '',
                    requiredquantity: '',
                    deliveryoption: '',
                },
            })

        default:
            return state;
    }
}