import React, { Component } from 'react'
import { connect } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Link } from 'react-router-dom';
import ImportedURL from '../../common/api';
import axios from 'axios';
import SpinnerComponent from '../../utilities/Spinner/spinner';

class DashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usercount: 0,
            sellingrequestcount: 0,
            createpostcount: 0,
            categorycount: 0,
            spinner: false,
        }
    }
    componentDidMount() {
        this.setState({ spinner: true });
        axios.post(ImportedURL.API.listDashBoardCount)
            .then((res) => {
                if (res.data) {
                    const { usercount, sellingrequestcount, createpostcount, categorycount } = res.data
                    this.setState({ usercount: usercount, sellingrequestcount: sellingrequestcount, createpostcount: createpostcount, categorycount: categorycount })
                }
                this.setState({ spinner: false });
            }).catch(({ response }) => { this.setState({ spinner: false }); });
    }

    render() {
        const { usercount, sellingrequestcount, createpostcount, categorycount } = this.state
        return (
            <>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-6 col-md-6 col-xl-3">
                                <Link to="/users-list">
                                    <div className="card">
                                        <div className="card-body ribbon">
                                            <div className="ribbon-box orange">{usercount}</div>
                                            <a className="my_sort_cut text-muted" href="#">
                                                <i className="fa fa-users"></i><span>Users</span>
                                            </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-6 col-md-6 col-xl-3">
                                <Link to="/list-Post">
                                    <div className="card">
                                        <div className="card-body ribbon">
                                            <div className="ribbon-box orange">{createpostcount}</div>
                                            <a className="my_sort_cut text-muted" href="#">
                                                <i className="fa fa-envelope"></i><span>Post</span>
                                            </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-6 col-md-6 col-xl-3">
                                <Link to="/list-sellingrequest">
                                    <div className="card">
                                        <div className="card-body ribbon">
                                            <div className="ribbon-box orange">{sellingrequestcount}</div>
                                            <a className="my_sort_cut text-muted" href="#">
                                                <i className="fa fa-shopping-cart"></i><span>Selling Request</span>
                                            </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-6 col-md-6 col-xl-3">
                                <Link to="/list-categories">
                                    <div className="card">
                                        <div className="card-body ribbon">
                                            <div className="ribbon-box orange">{categorycount}</div>
                                            <a className="my_sort_cut text-muted" href="#">
                                                <i className="fa fa-cubes"></i><span>Categories</span>
                                            </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <SpinnerComponent spinner={this.state.spinner} />
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);