import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import imagepath from "../../../src/assets/images/fakeprofile.jpg"
import jsPDF from "jspdf";
import Spinner from 'react-bootstrap/Spinner';
import { AC_LIST_SELLING_REQUEST_DETAIL, AC_SELLING_REQUEST_LIST_SPINNER, AC_VIEW_SELLING_REQUEST_DETAIL, AC_EMPTY_SELLING_REQUEST_FEES_SPINNER } from "../../actions/sellingAction";
import { onErrorImage } from '../../common/validate';
import ExportPdfComponent from '../../utilities/ExportPDF/exportpdf';
import AgGridPiganationComponent from '../../utilities/AgGridReact/aggridpagination';


const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    saving: false,
    communicationemailValidError: false,
    columnDefs: [
        {
            headerName: 'Driver Image', field: 'driverimage', width: 100, floatingFilter: false, headerClass: 'ag-center-header', pinned: 'left',
            cellRendererFramework: function (params) {
                if (params.data) {
                    return <div>
                        <img style={{ width: '40px', height: '40px', borderRadius: '0px' }}
                            src={params.data.image ? ImportedURL.LIVEURL + params.data.image : '../assets/images/xs/avatar2.jpg'}
                            onError={onErrorImage}
                            alt=""
                        />
                    </div>
                }
            }
        },
        { headerName: 'Main Category', field: 'categoryname', headerClass: 'ag-center-header', width: 200, floatingFilter: true, pinned: 'left' },
        { headerName: 'Sub category', field: 'subcategoryname', headerClass: 'ag-center-header', width: 200, floatingFilter: true, pinned: 'left' },
        { headerName: 'Pricedetail', field: 'pricedetail', width: 200, floatingFilter: true, },
        // { headerName: 'Address', field: 'address', headerClass: 'ag-center-header', width: 200, floatingFilter: true },
        { headerName: 'Shipments', field: 'shipments', headerClass: 'ag-center-header', width: 100, floatingFilter: true },
        { headerName: 'vehicle', field: 'vechile', headerClass: 'ag-center-header', width: 100, floatingFilter: true },
        { headerName: 'Quantity', field: 'quantity', headerClass: 'ag-center-header', width: 100, floatingFilter: true },
        {
            headerName: 'Order', width: 100, field: 'order', headerClass: 'ag-center-header', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data) {
                    if (params.data.order) {
                        return `<span type="button" class="tag tag-green"  data-action-type="Order">Confirmed</span>`;
                    } else {
                        return '<span type="button" class="tag tag-danger not-confirm"  data-action-type="Order">Not Confirmed</span>';
                    }
                }
            }
        },

        {
            headerName: 'Status', width: 100, field: 'status', headerClass: 'ag-center-header', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data) {
                    if (params.data.status) {
                        return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                    } else {
                        return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                    }
                }
            }
        },
        {
            headerName: 'Actions', width: 130, field: 'actions', headerClass: 'ag-center-header', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {

        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 140,
    },
    perPage: 25,
}
class Sellingrequest extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['categoryname', 'subcategoryname', 'pricedetail', 'address', "shipments", 'vechile', 'quantity',],
            fileName: 'sellingrequest.csv',
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };
    exportPDF = () => {
        const exportData = this.props.SellingrequestState.listselling;
        const title = "Selling Request";
        const headers = [['#', 'Main Category', 'Sub category', "Pricedetail", 'Address', 'Shipments', 'Vechile', 'Quantity',]];
        const objName = ['categoryname', 'subcategoryname', 'pricedetail', 'address', 'shipments', 'vechile', 'quantity'];
        const pdfName = "sellingrequest.pdf";
        ExportPdfComponent(exportData, title, headers, objName, pdfName);
    }
    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.setState({ viewRedirect: true, id: event.data._id });
            this.props.ViewSpinner();
            this.props.Viewsellingrequest(event.data._id);
        }
        if (value === 'Order') {
            Swal.fire({
                title: 'Are you sure to change the order?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.orderconfirm, { id: event.data._id, order: !event.data.order, model: 'sellingrequests' })
                        .then((data) => {
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                            Success('Order updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }

        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'sellingrequests' })
                        .then((data) => {
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }

    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {
                // Grid params for filter and sort
                const page = params.endRow / this.state.perPage;
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                this.props.ListSpinner();
                await this.props.Listsellingrequest({ ...query, perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {} });
                const rowData = this.props.SellingrequestState.listselling;
                const total = this.props.SellingrequestState.totelselling;
                const template = '<div><button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-file-text-o" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
                    + '</div>'
                rowData.forEach(object => {
                    object.actions = template;
                });
                params.successCallback(rowData, total);

            }
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    componentDidUpdate(prevProps, prevState) {
        // To set data once grid api is updated
        if (this.gridApi) {
            if (prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
    }
    render() {
        const { SellingrequestState } = this.props
        const rowData = SellingrequestState.listselling;
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const ListSpinner = SellingrequestState.ListSpinner;
        if (this.state.viewRedirect) return <Redirect to={'/View-sellingrequest/' + this.state.id} />
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-sellingrequest"><h6 className="highlights_breadcrump">Selling Request List</h6></Link></p>
                </div>
                <div className="section-body pt-3 user_sec">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'><i className="fa fa-shopping-cart"></i></div>
                                        <h3 className="card-title">Selling Request List</h3>
                                        <div className="card-options">
                                            <>
                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                </div>
                                            </>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                <label className="form-label">Limit</label>
                                                <div className="form-group">
                                                    <Select
                                                        onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                        options={limitOptions}
                                                        defaultValue={limitOptions[0]}
                                                        className='limit_size'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <AgGridPiganationComponent spinner={ListSpinner} rowData={rowData} state={this.state} onRowClicked={(e) => this.onRowClicked(e)} onGridReady={(e) => this.onGridReady(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    SellingrequestState: state.sellingrequest,
});
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        Viewsellingrequest: AC_VIEW_SELLING_REQUEST_DETAIL,
        Listsellingrequest: AC_LIST_SELLING_REQUEST_DETAIL,
        ViewSpinner: AC_EMPTY_SELLING_REQUEST_FEES_SPINNER,
        ListSpinner: AC_SELLING_REQUEST_LIST_SPINNER

    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Sellingrequest);