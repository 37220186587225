import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import Layout from './components/Shared/Layout';
import Login from './components/Authentication/login';
import ForgotPassword from './components/Authentication/forgotpassword';
import NotFound from './components/Authentication/404';
import InternalServer from './components/Authentication/500';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AC_ACCOUNT_DETAILS, AC_PREVILEGE_DETAILS } from './actions/accountAction';

const token = localStorage.getItem("wastemanagement");
class App extends Component {
	componentDidMount() {
		if (token) {
			this.props.AccountDetails();
			this.props.PrevilegesDetails();
		}
	}
	render() {
		const { darkMode, boxLayout, darkSidebar, iconColor, gradientColor, rtl, fontType } = this.props;
		return (
			<div className={`${darkMode ? "dark-mode" : ""}${darkSidebar ? "" : "sidebar_dark"} ${iconColor ? "iconcolor" : ""} ${gradientColor ? "gradient" : ""} ${rtl ? "rtl" : ""} ${fontType ? fontType : ""}${boxLayout ? "boxlayout" : ""}`}>
				<Router>
					{token ?
						<Switch>
							{/* <Route exact={true} path="/" component={Home} /> */}
							<Route path="/notfound" component={NotFound} />
							<Route path="/internalserver" component={InternalServer} />
							<Route path="/" component={Layout} />
						</Switch> :
						<Switch>
							{/* <Route exact={true} path="/" component={Home} /> */}
							<Route path="/forgotpassword" component={ForgotPassword} />
							<Route path="/login" component={Login} />
							<Route path="/" component={Login} />
						</Switch>
					}

				</Router>
			</div>
		);
		// let navHeader = this.state.visibility ? <Layout /> : <Login />;
		// return (
		//   <div>
		//       {navHeader}
		//   </div>
		// )
	}
}
const mapStateToProps = state => ({
	darkMode: state.settings.isDarkMode,
	darkSidebar: state.settings.isDarkSidebar,
	iconColor: state.settings.isIconColor,
	gradientColor: state.settings.isGradientColor,
	rtl: state.settings.isRtl,
	fontType: state.settings.isFont,
	boxLayout: state.settings.isBoxLayout,
	notification: state.notification
})

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ AccountDetails: AC_ACCOUNT_DETAILS, PrevilegesDetails: AC_PREVILEGE_DETAILS, }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App)