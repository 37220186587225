import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_SELLING_REQUEST_DETAIL } from '../../actions/sellingAction';
import ImportedURL from '../../common/api';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import imagepath from "../../../src/assets/images/noimages.png"
import { onErrorImage } from '../../common/validate';

class Viewsellingrequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            driverimage: false,
            frontimage: false,
            backimage: false,
            leftimage: false,
            rightimage: false,
            liceneseimage: false,
            aadharimage: false,
        };
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.AC_VIEW_SELLING_REQUEST_DETAIL(id)
    }

    render() {
        const { photoIndex, driverimage, frontimage, backimage, leftimage, rightimage, liceneseimage, aadharimage } = this.state;
        const { SellingrequestState } = this.props;
        const data = SellingrequestState.selling;


        // const frontimages = [ImportedURL.LIVEURL + data.frontimage]
        // const backimages = [ImportedURL.LIVEURL + data.backimage]
        // const leftimages = [ImportedURL.LIVEURL + data.leftimage]
        // const rightimages = [ImportedURL.LIVEURL + data.rightimage]
        const liceneseimages = [ImportedURL.LIVEURL + data.liceneseimage]
        const driverimages = [ImportedURL.LIVEURL + data.driverimage]
        const aadharimages = [ImportedURL.LIVEURL + data.aadharimage]

        const vechilesarray = []
        if (data.frontimage) {
            vechilesarray.push(
                ImportedURL.LIVEURL + data.frontimage
            )
        }
        if (data.backimage) {
            vechilesarray.push(
                ImportedURL.LIVEURL + data.backimage
            )
        } if (data.leftimage) {
            vechilesarray.push(
                ImportedURL.LIVEURL + data.leftimage
            )
        } if (data.rightimage) {
            vechilesarray.push(
                ImportedURL.LIVEURL + data.rightimage
            )
        }


        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-sellingrequest"><h6>Selling Request List</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump">View Selling Request</h6></p>
                </div>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Project-OnGoing" role="tabpanel">
                                <div className='row'>
                                    <div className="col-lg-12   col-md-12">
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className="card ">
                                                    <div className="card-header">
                                                        <div className='rounded_icon'>
                                                            <i className="fa fa-shopping-cart"></i>
                                                        </div>
                                                        <h3 className="card-title">View Selling Request</h3>
                                                        <div class="card-options">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <ul className="nav nav-tabs page-header-tab"></ul>

                                                                <div className="header-action">
                                                                    <Link to='/list-sellingrequest'>
                                                                        <button type="button" onClick={this.handleclear} className="btn btn-round btn-primary">
                                                                            <i className="fa fa-solid fa-chevron-left mr-2"></i>Back
                                                                        </button>
                                                                    </Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">

                                                            <div className="col-4 py-1" style={{ marginTop: "40px" }}><strong>DriverImage</strong></div>
                                                            <div className="col-1 py-1" style={{ marginTop: "40px" }}><strong>:</strong></div>
                                                            <div className="col-7 py-1">{<img style={{ width: '100px', height: '100px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + data.driverimage} onClick={() => this.setState({ driverimage: true })} alt="" onError={onErrorImage} />}
                                                            </div>
                                                            <div>
                                                                {driverimage && (
                                                                    <Lightbox
                                                                        mainSrc={driverimages[photoIndex]}
                                                                        onCloseRequest={() => this.setState({ driverimage: false })}
                                                                    />
                                                                )}
                                                            </div>



                                                            <div className="col-4 py-1"><strong>Main category</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.maincategory}</div>

                                                            <div className="col-4 py-1"><strong>Sub category</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.subcategory}</div>

                                                            <div className="col-4 py-1"><strong>Pricedetail</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.pricedetail}</div>

                                                            <div className="col-4 py-1"><strong>Quantity</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.quantity}</div>

                                                            <div className="col-4 py-1"><strong>vehicle</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1"> {data.vechile}</div>

                                                            <div className="col-4 py-1"><strong>Shipments</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.shipments}</div>

                                                            <div className="col-4 py-1"><strong>Order</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1" >{data.order ? 'Confirmed' : 'Not Confirmed'}</div>


                                                            <div className="col-4 py-1"><strong>Address</strong></div>
                                                            <div className="col-1 py-1"><strong>:</strong></div>
                                                            <div className="col-7 py-1">{data.address}</div>

                                                            <div className="col-4 py-1" style={{ marginTop: "50px" }}><strong>Vehicles</strong></div>
                                                            <div className="col-1 py-1" style={{ marginTop: "50px" }}><strong>:</strong></div>
                                                            <div className="col-7 py-1">
                                                                <div class="card">
                                                                    <div class="card-body">
                                                                        <div class="row ">
                                                                            <div class="col">
                                                                                <div class="form-group">
                                                                                    <p>Front</p>
                                                                                    {<img style={{ width: '100px', height: '100px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + data.frontimage} onClick={() => this.setState({ frontimage: true })} alt="" onError={onErrorImage} />}
                                                                                    <div>
                                                                                        {frontimage && (
                                                                                            <Lightbox
                                                                                                mainSrc={vechilesarray[photoIndex]}
                                                                                                nextSrc={vechilesarray[(photoIndex + 1) % vechilesarray.length]}
                                                                                                prevSrc={vechilesarray[(photoIndex + vechilesarray.length - 1) % vechilesarray.length]}
                                                                                                onCloseRequest={() => this.setState({ frontimage: false })}
                                                                                                onMovePrevRequest={() =>
                                                                                                    this.setState({
                                                                                                        photoIndex: (photoIndex + vechilesarray.length - 1) % vechilesarray.length,
                                                                                                    })
                                                                                                }
                                                                                                onMoveNextRequest={() =>
                                                                                                    this.setState({
                                                                                                        photoIndex: (photoIndex + 1) % vechilesarray.length,
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col">
                                                                                <div class="form-group">
                                                                                    <p>Back</p>
                                                                                    {<img style={{ width: '100px', height: '100px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + data.backimage} onClick={() => this.setState({ backimage: true })} alt="" onError={onErrorImage} />}
                                                                                    <div>
                                                                                        {backimage && (
                                                                                            <Lightbox
                                                                                                mainSrc={vechilesarray[photoIndex]}
                                                                                                onCloseRequest={() => this.setState({ backimage: false })}
                                                                                                nextSrc={vechilesarray[(photoIndex + 1) % vechilesarray.length]}
                                                                                                prevSrc={vechilesarray[(photoIndex + vechilesarray.length - 1) % vechilesarray.length]}
                                                                                                onMovePrevRequest={() =>
                                                                                                    this.setState({
                                                                                                        photoIndex: (photoIndex + vechilesarray.length - 1) % vechilesarray.length,
                                                                                                    })
                                                                                                }
                                                                                                onMoveNextRequest={() =>
                                                                                                    this.setState({
                                                                                                        photoIndex: (photoIndex + 1) % vechilesarray.length,
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col">
                                                                                <div class="form-group">
                                                                                    <p>Left</p>
                                                                                    {<img style={{ width: '100px', height: '100px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + data.leftimage} onClick={() => this.setState({ leftimage: true })} alt="" onError={onErrorImage} />}
                                                                                    <div>
                                                                                        {leftimage && (
                                                                                            <Lightbox
                                                                                                mainSrc={vechilesarray[photoIndex]}
                                                                                                onCloseRequest={() => this.setState({ leftimage: false })}
                                                                                                nextSrc={vechilesarray[(photoIndex + 1) % vechilesarray.length]}
                                                                                                prevSrc={vechilesarray[(photoIndex + vechilesarray.length - 1) % vechilesarray.length]}
                                                                                                onMovePrevRequest={() =>
                                                                                                    this.setState({
                                                                                                        photoIndex: (photoIndex + vechilesarray.length - 1) % vechilesarray.length,
                                                                                                    })
                                                                                                }
                                                                                                onMoveNextRequest={() =>
                                                                                                    this.setState({
                                                                                                        photoIndex: (photoIndex + 1) % vechilesarray.length,
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col">
                                                                                <div class="form-group">
                                                                                    <p>Right</p>
                                                                                    {<img style={{ width: '100px', height: '100px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + data.rightimage} onClick={() => this.setState({ rightimage: true })} alt="" onError={onErrorImage} />}
                                                                                    <div>
                                                                                        {rightimage && (
                                                                                            <Lightbox
                                                                                                mainSrc={vechilesarray[photoIndex]}
                                                                                                onCloseRequest={() => this.setState({ rightimage: false })}
                                                                                                nextSrc={vechilesarray[(photoIndex + 1) % vechilesarray.length]}
                                                                                                prevSrc={vechilesarray[(photoIndex + vechilesarray.length - 1) % vechilesarray.length]}
                                                                                                onMovePrevRequest={() =>
                                                                                                    this.setState({
                                                                                                        photoIndex: (photoIndex + vechilesarray.length - 1) % vechilesarray.length,
                                                                                                    })
                                                                                                }
                                                                                                onMoveNextRequest={() =>
                                                                                                    this.setState({
                                                                                                        photoIndex: (photoIndex + 1) % vechilesarray.length,
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-4 py-1" style={{ marginTop: "50px" }}><strong>Licenese  image</strong></div>
                                                            <div className="col-1 py-1" style={{ marginTop: "50px" }}><strong>:</strong></div>
                                                            <div className="col-7 py-1">{<img style={{ width: '150px', height: '150px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + data.liceneseimage} onClick={() => this.setState({ liceneseimage: true })} alt="" onError={onErrorImage} />}</div>
                                                            <div>
                                                                {liceneseimage && (
                                                                    <Lightbox
                                                                        mainSrc={liceneseimages[photoIndex]}
                                                                        onCloseRequest={() => this.setState({ liceneseimage: false })}
                                                                    />
                                                                )}
                                                            </div>

                                                            <div className="col-4 py-1" style={{ marginTop: "50px" }}><strong>Aadhar Image</strong></div>
                                                            <div className="col-1 py-1" style={{ marginTop: "50px" }}><strong>:</strong></div>
                                                            <div className="col-7 py-1">{<img style={{ width: '150px', height: '150px', borderRadius: '0px' }} src={ImportedURL.LIVEURL + data.aadharimage} onClick={() => this.setState({ aadharimage: true })} alt="" onError={onErrorImage} />}</div>
                                                            <div>
                                                                {aadharimage && (
                                                                    <Lightbox
                                                                        mainSrc={aadharimages[photoIndex]}
                                                                        onCloseRequest={() => this.setState({ aadharimage: false })}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    SellingrequestState: state.sellingrequest,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ AC_VIEW_SELLING_REQUEST_DETAIL }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Viewsellingrequest);