import { Error } from '../../common/swal';

const ErrorResponseComponent = (response, existData) => {
    if (response) {
        if (response.status == 401) {
            Error('Something wrong, Retry again!')
        } else if (response.status == 502) {
            Error(response.status + ' Bad Gateway')
        } else if (response.status == 500) {
            Error(response.status + ' Internal Server Error')
        } else if (response.status == 400) {
            Error(response.status + ' Bad request')
        } else if (response.status == 409) {
            Error(existData ? existData + ' already exist' : "Already exists")
        } else if (response.status == 510) {
            Error('Email does not exit')
        } else if (response.status == 511) {
            Error('Invalid password for Admin')
        } else {
            Error('Bad request')
        }

    } else {
        Error('Bad request')
    }
}

export default ErrorResponseComponent
