import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import jsPDF from "jspdf";
import { Imagevalidation, onErrorImage } from '../../common/validate';
import Spinner from 'react-bootstrap/Spinner';
import { AC_HANDLE_CATEGOIRES_CHANGE, AC_LIST_CATEGOIRES_DETAIL, AC_CATEGOIRES_LIST_SPINNER, AC_VIEW_CATEGOIRES_DETAIL, AC_RESET_CATEGOIRES_DETAIL, AC_EMPTY_CATEGOIRES_DETAIL, AC_EMPTY_CATEGOIRES_FEES_SPINNER } from '../../actions/categoriesAction';
import { AC_LIST_MAINCATEGORY_DETAIL } from "../../actions/maincategory";
import { AC_LIST_SUBCATEGORY_DETAIL } from "../../actions/subcategoryAction";
import imagepath from "../../../src/assets/images/noimages.png"
import ExportPdfComponent from '../../utilities/ExportPDF/exportpdf';
import AgGridPiganationComponent from '../../utilities/AgGridReact/aggridpagination';

const initialState = {
    id: '',
    viewRedirect: false,
    editRedirect: false,
    modalType: "Add",
    Image: '',
    CategorynameError: false,
    subcategorynameError: false,
    imageError: false,
    quantityError: false,
    marketpriceError: false,
    MaincategoryError: false,
    saving: false,
    communicationemailValidError: false,
    columnDefs: [
        {
            headerName: 'Image', field: 'image', width: 100, floatingFilter: false, headerClass: 'ag-center-header', pinned: 'left',
            cellRendererFramework: function (params) {
                if (params.data) {
                    return <div>
                        <img style={{ width: '40px', height: '40px', borderRadius: '0px' }}
                            src={ImportedURL.LIVEURL + params.data.image} alt=""
                            onError={onErrorImage}
                        />
                    </div>
                }
            }

        },
        { headerName: 'Main Category', field: 'categoryname', headerClass: 'ag-center-header', width: 200, floatingFilter: true, pinned: 'left' },
        { headerName: 'Sub Category', field: 'subcategoryname', headerClass: 'ag-center-header', width: 200, floatingFilter: true, pinned: 'left' },
        { headerName: 'Categories', field: 'categories', width: 200, floatingFilter: true, pinned: 'left', },
        { headerName: 'Quantity', field: 'quantity', headerClass: 'ag-center-header', width: 200, floatingFilter: true },
        { headerName: 'Market Price', field: 'marketprice', headerClass: 'ag-center-header', width: 100, floatingFilter: true },


        {
            headerName: 'Status', width: 100, field: 'status', headerClass: 'ag-center-header', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                if (params.data) {
                    if (params.data.status) {
                        return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                    } else {
                        return '<span type="button" class="tag tag-danger"  data-action-type="Status">Inactive</span>';
                    }
                }
            }
        },
        {
            headerName: 'Actions', width: 130, field: 'actions', headerClass: 'ag-center-header', cellStyle: { textAlign: 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {

        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 140,
    },
    perPage: 25,
}
class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    changeModal = (e) => {
        this.setState({ modalType: e.target.id });
        this.setState({ image: "" });
        this.props.ResetCategories();
    }

    onChangeSelect = (e) => {

        const { name, value, label } = e;
        const Error = name + "Error";
        if (name == 'categoryname') {
            if (value) {
                this.props.HandleInputChange('categoryname', value);
                this.setState({ MaincategoryError: false })
            } else {
                this.setState({ MaincategoryError: true })
            }
        }
    }
    // onChangetype = (e) => {
    //     const { name, value, label } = e;
    //     const Error = name + "Error";
    //     if (name == 'categories') {
    //         if (value) {
    //             this.props.HandleInputChange('categories', value);
    //             this.setState({ categoryError: false })
    //         } else {
    //             this.setState({ categoryError: true })
    //         }
    //     }

    // }
    handleFieldChange = (e) => {

        const name = e.target.name;
        const value = e.target.value;
        this.props.HandleInputChange(name, value);
        if (name === 'categories') {
            this.setState({ categories: value });
            var categories = value;
            if (categories) {
                this.setState({ categoryError: false })
            } else {
                this.setState({ categoryError: true })
            }
        }
        if (name === 'quantity') {
            this.setState({ quantity: value });
            var quantity = value;
            if (quantity) {
                this.setState({ quantityError: false })
            } else {
                this.setState({ quantityError: true })
            }
        }
        if (name === 'marketprice') {
            this.setState({ marketprice: value });
            var marketprice = value;
            if (marketprice) {
                this.setState({ marketpriceError: false })
            } else {
                this.setState({ marketpriceError: true })
            }
        }


    }
    onChangeFile = e => {

        const imgvalidate = Imagevalidation(e.target.files[0]);
        if (imgvalidate) {
            this.setState({ imageError: false })
            this.setState({ image: e.target.files[0] });
            var reader = new FileReader();
            var url = reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = function (e) {
                this.setState({
                    imageSrc: [reader.result]
                })
            }.bind(this);
            this.props.HandleInputChange('image', e.target.files[0]);
        } else {
            this.setState({ name: '' });
            Error('Invalid file extension');
            // alert('Invalid file extension')


        }



        // this.setState({ ViewSpinner: false })
        // const { name, value } = e.target;
        // if (name === 'image') {
        //     this.setState({ image: e.target.files[0], imageError: false })
        // }
        // this.props.HandleInputChange(name, e.target.files[0]);
    }
    handleChangeselect = (e) => {

        const { name, value, label } = e;
        const Error = name + "Error";
        if (name == 'subcategoryname') {
            if (value) {
                this.props.HandleInputChange('subcategoryname', value);
                this.setState({ subcategoryname: false })
            } else {
                this.setState({ subcategoryname: true })
            }
        }
    }
    exportToCSV = () => {
        this.gridApi.exportDataAsCsv({
            columnKeys: ['categoryname', 'subcategoryname', 'categories', "quantity", "marketprice"],
            fileName: 'categories.csv',
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    exportPDF = () => {
        const exportData = this.props.CategoriesState.listcategories;
        const title = "Categories";
        const headers = [['#', 'Main Category', 'Sub Category', 'Categories', "Quantity", "Market Price"]];
        const objName = ['categoryname', 'subcategoryname', 'categories', 'quantity', 'marketprice'];
        const pdfName = "categories.pdf";
        ExportPdfComponent(exportData, title, headers, objName, pdfName);
    }
    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.setState({ viewRedirect: true, id: event.data._id });
            this.props.ViewSpinner();
            this.props.ViewCategories(event.data._id);
        }
        if (value === 'Edit') {
            this.setState({ MaincategoryError: false, imageError: false, subcategorynameError: false, categoryError: false, quantityError: false, marketpriceError: false });
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.ViewSpinner();
            this.props.ViewCategories(event.data._id);
        }
        if (value === 'Delete') {
            Swal.fire({
                title: 'Are you sure want to delete?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.deletecategories + "/" + event.data._id)
                        .then((data) => {
                            Success(data.statusText);
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                        })
                }
            })
        }
        if (value === 'Status') {
            Swal.fire({
                title: 'Are you sure to change the status?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: 'Ok',
                imageUrl: '../../assets/images/status.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'categories' })
                        .then((data) => {
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                            Success('Status updated successfully')
                        }).catch(({ response }) => { if (response) Error(response.statusText) });
                }
            })
        }
    }
    submit = () => {

        const { CategoriesState } = this.props;
        const data = CategoriesState.categories;

        let valid = 1
        if (!data.categoryname) {
            this.setState({ MaincategoryError: true });
            valid = 0
        }
        if (!data.categories) {
            this.setState({ categoryError: true });
            valid = 0
        }
        if (!data.subcategoryname) {
            this.setState({ subcategorynameError: true });
            valid = 0
        }
        if (!data.image) {
            this.setState({ imageError: true });
            valid = 0
        }
        if (!data.quantity) {
            this.setState({ quantityError: true });
            valid = 0
        }
        if (!data.marketprice) {
            this.setState({ marketpriceError: true });
            valid = 0
        }
        if (valid) {
            var formData = new FormData();
            formData.append("categoryname", data.categoryname);
            formData.append("image", data.image);
            formData.append("subcategoryname", data.subcategoryname);
            formData.append("categories", data.categories);
            formData.append("quantity", data.quantity);
            formData.append("marketprice", data.marketprice);

            // this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addcategories, formData)
                    .then((formdata) => {
                        this.setState({ image: '' });
                        Success(formdata.statusText);
                        this.setState({ save: false });

                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        this.props.ResetCategories();

                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ save: false });
                        if (response.status == 409) {
                            Error('Select Type Already Exist')
                        } else {
                            Error(response.statusText)
                        }
                        this.setState({ saving: false })
                    });
            } else {
                axios.post(ImportedURL.API.updatecategories + '/' + this.state.id, formData)
                    .then((res) => {
                        Success(res.statusText);
                        this.setState({ save: false });
                        this.props.ResetCategories();
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        Error(response.statusText)
                        this.setState({ save: false });
                    });
            }
        }
    }


    componentDidMount() {
        this.props.listmaincategory();
        this.props.listSubcategory();
    }
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {

                // Grid params for filter and sort
                const page = params.endRow / this.state.perPage;
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                this.props.ListSpinner();
                await this.props.ListCategories({ ...query, perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {} });
                const rowData = this.props.CategoriesState.listcategories;
                const total = this.props.CategoriesState.totelcategories;

                const editOption = '<button type="button" data-toggle="modal" data-target="#exampleModal" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit" data-toggle="tooltip" title="Edit"></i></button>';
                const deleteOption = '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete" data-toggle="tooltip" title="Delete"/></button>';
                const template = '<div><button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-file-text-o" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
                    + editOption
                    // + deleteOption
                    + '</div>'
                rowData.forEach(object => {
                    object.actions = template;
                });

                params.successCallback(rowData, total);

            }
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    componentDidUpdate(prevProps, prevState) {

        // To set data once grid api is updated
        if (this.gridApi) {
            if (prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
    }
    handleImageError = (e) => {
        e.target.onerror = null;
        e.target.src = imagepath;
    }
    render() {
        const { SubcategoryState, CategoryState, CategoriesState } = this.props;
        const rowData = CategoriesState.listcategories;
        const listmaincategory = CategoryState.listCategory;
        const listsubcategory = SubcategoryState.listSubcategory;
        const data = CategoriesState.categories;
        const ViewSpinner = CategoriesState.ViewSpinner;
        const ListSpinner = CategoriesState.ListSpinner;
        if (this.state.viewRedirect) return <Redirect to={'/view-categories/' + this.state.id} />

        if (data !== undefined) {
            var imageData = data.image;
        }

        const categoryOptions = [];
        listmaincategory.filter(filterItem => filterItem.status === true).map(item => {
            categoryOptions.push({ label: item.categoryname, value: item._id, name: 'categoryname' });
        })
        let selectedCatagoryname = '';
        if (data.categoryname) {
            selectedCatagoryname = listmaincategory.find(e => e._id === data.categoryname)
        }

        const subcategorynameOptions = [];
        listsubcategory.filter(filterItem => filterItem.status === true).map(item => {
            if (data.categoryname == item.categorynameid) {
                subcategorynameOptions.push({ label: item.subcategoryname, value: item._id, name: 'subcategoryname' });
            }
        })
        let selectedSubCatagoryname = '';
        if (data.subcategoryname) {
            selectedSubCatagoryname = listsubcategory.find(e => e._id === data.subcategoryname)
        }

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>Dashboard</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-categories"><h6 className="highlights_breadcrump">Categories List</h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="icon-bar-chart mr-2  "></i></div>
                                                <h3 className="card-title">Categories List</h3>
                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action">
                                                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                        </div>
                                                    </div>

                                                    <>
                                                        <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                            <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                        </div>
                                                    </>

                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">Limit</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <AgGridPiganationComponent spinner={ListSpinner} rowData={rowData} state={this.state} onRowClicked={(e) => this.onRowClicked(e)} onGridReady={(e) => this.onGridReady(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* model */}

                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><i className="icon-bar-chart mr-2 "></i></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} Categories</h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label className="form-label">Main Category<span className="ml-1" style={{ color: 'red' }}> *</span></label>
                                                    <Select
                                                        onChange={this.onChangeSelect}
                                                        value={selectedCatagoryname ? { label: selectedCatagoryname.categoryname, value: selectedCatagoryname._id, name: "categoryname" } : ''}
                                                        options={categoryOptions}
                                                        name='categoryname'
                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.MaincategoryError ? "block" : 'none' }}>Main Category is required</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label className="form-label">Sub Category<span className='ml-1' style={{ color: 'red' }}> *</span></label>
                                                    <Select
                                                        onChange={this.handleChangeselect}
                                                        name="subcategoryname"
                                                        value={selectedSubCatagoryname ? { label: selectedSubCatagoryname.subcategoryname, value: selectedSubCatagoryname._id, name: "subcategoryname" } : ''}
                                                        options={subcategorynameOptions}
                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.subcategorynameError ? "block" : 'none' }}>Sub Category is required</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label className="form-label">Categories<span className="ml-1" style={{ color: 'red' }}> *</span></label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType === "View"} name='categories' onChange={this.handleFieldChange}
                                                        value={data.categories}
                                                        placeholder="Categories" />
                                                    <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>Categories is required</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label className="form-label">Quantity<span className="ml-1" style={{ color: 'red' }}> *</span></label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType === "View"} name='quantity' onChange={this.handleFieldChange}
                                                        value={data.quantity}
                                                        placeholder="Quantity" />
                                                    <div className="invalid-feedback" style={{ display: this.state.quantityError ? "block" : 'none' }}>Quantity is required</div>
                                                </div>

                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label className="form-label">Market Price<span className="ml-1" style={{ color: 'red' }}> *</span></label>
                                                    <input type="text" className="form-control" disabled={this.state.modalType === "View"} name='marketprice' onChange={this.handleFieldChange}
                                                        value={data.marketprice}
                                                        placeholder="Market Price" />
                                                    <div className="invalid-feedback" style={{ display: this.state.marketpriceError ? "block" : 'none' }}>Market Price is required</div>
                                                </div>

                                            </div>
                                            <div className='col-lg-12'>
                                                <div className="form-group">
                                                    <label className="form-label">Image<span className="ml-1" style={{ color: 'red' }}> *</span></label>
                                                    <input name="image" type="file" accept="image/png,image/jpg,image/jpeg" className="form-control" onChange={this.onChangeFile} />
                                                    <div className="invalid-feedback" style={{ display: this.state.imageError ? "block" : 'none' }}>Image is required</div>
                                                </div>
                                                <div className="form-group">
                                                    {
                                                        this.state.modalType == "Add"
                                                        &&
                                                        (this.state.image
                                                            ?
                                                            <div style={{ width: '200px', height: '100px' }}>
                                                                <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : null} alt='12' onError={onErrorImage} />
                                                            </div>
                                                            : '')
                                                    }
                                                    {
                                                        this.state.modalType == "Edit"
                                                            ?
                                                            <div className="form-group">
                                                                <div style={{ width: '200px', height: '100px' }}>
                                                                    <img style={{ width: '60px', height: '60px', objectFit: 'cover' }} src={this.state.image ? URL.createObjectURL(this.state.image) : ImportedURL.LIVEURL + imageData} alt="" onError={onErrorImage} />
                                                                </div>
                                                            </div>
                                                            : ''
                                                    }
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                                {this.state.modalType == "Edit" ?
                                    <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>
                                    : <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>}
                            </div>
                            <div id='overlay-model' style={{ display: ViewSpinner ? 'block' : 'none' }}></div>
                            {ViewSpinner ?
                                <div className='model_loader_poss'>
                                    <img className='loader_img_style_model' src='../../assets/images/recycle loader.gif' />
                                    <Spinner className='spinner_load_model' variant="info" >
                                    </Spinner>
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    CategoryState: state.maincategory,
    SubcategoryState: state.subcategory,
    CategoriesState: state.Categories,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_HANDLE_CATEGOIRES_CHANGE,
        ListCategories: AC_LIST_CATEGOIRES_DETAIL,
        ViewCategories: AC_VIEW_CATEGOIRES_DETAIL,
        ResetCategories: AC_RESET_CATEGOIRES_DETAIL,
        EmptyCategories: AC_EMPTY_CATEGOIRES_DETAIL,
        listmaincategory: AC_LIST_MAINCATEGORY_DETAIL,
        listSubcategory: AC_LIST_SUBCATEGORY_DETAIL,
        ViewSpinner: AC_EMPTY_CATEGOIRES_FEES_SPINNER,
        ListSpinner: AC_CATEGOIRES_LIST_SPINNER

    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Categories);
